import { pbGenderToString } from "@/store/modules/dealer/dealer.types";
import { Coordinate, Location } from "acard-protos/js/meta/meta_pb";
import { ShortUserProfile } from "acard-protos/js/v1/profile/profile_pb";
import { DealerProfile, FarmerProfile } from "acard-protos/js/v1/user/user_pb";
import { BaseDistrict, County, Parish, SubCounty, Village } from "akwap";

export type FormField<T> = {
  data: T | null;
  error: null;
};

export type DefaultState = {
  dialog: boolean;
  loading: boolean;
  error: ErrorState;
};

export type ErrorState = {
  error: boolean;
  errorMessage: string | null;
};

export type ErrorHandler = (error: string) => void;

export type LocationInfo = {
  district?: BaseDistrict;
  county?: County;
  sub_county?: SubCounty;
  parish?: Parish;
  village?: Village;
};

export type Pagination = {
  page: number;
  limit: number;
  query?: string;
};

export type JsShortUserProfile = {
  // user's id
  id: number;

  // user's name
  name: string;

  // user's phone
  phone: string;

  // user's district
  district: string;

  // user's village
  village: string;
};

export type JsFarmerProfile = {
  // user's id
  id: number;

  // user's name
  name: string;

  // user's phone
  phone: string;

  // user's phone
  photo: string;

  // user's district
  district: string;

  // user's district
  gender: string;

  // user's village
  village: string;
};

export type JsAgentProfile = {
  // user's id
  id: number;

  // user's name
  name: string;

  // user's phone
  phone: string;

  // user's phone
  photo: string;

  // user's district
  district: string;

  // user's village
  village: string;
};

export type GpsCoordinate = {
  lat: number;
  lon: number;
};

export type UserLocation = {
  village: string;
  parish: string;
  sub_county: string;
  county: string;
  district: string;
};

export function pbShortUserProfile(
  profile: ShortUserProfile | undefined
): JsShortUserProfile | undefined {
  if (profile) {
    return {
      id: profile.getId(),
      name: profile.getName(),
      phone: profile.getPhone(),
      district: profile.getDistrict(),
      village: profile.getVillage(),
    };
  }
  return undefined;
}

export function pbFarmerProfile(
  profile: FarmerProfile | undefined
): JsFarmerProfile | undefined {
  if (profile) {
    return {
      id: profile.getId(),
      name: profile.getName(),
      phone: profile.getPhone(),
      district: profile.getDistrict(),
      village: profile.getVillage(),
      photo: profile.getPhoto(),
      gender: pbGenderToString(profile.getGender()) ?? "",
    };
  }
  return undefined;
}

export function pbAgentProfile(
  profile: DealerProfile | undefined
): JsAgentProfile | undefined {
  if (profile) {
    return {
      id: profile.getId(),
      name: profile.getName(),
      phone: profile.getPhone(),
      district: profile.getDistrict(),
      village: profile.getVillage(),
      photo: profile.getPhoto(),
    };
  }
  return undefined;
}

export function pbCoordinate(
  coordinate: Coordinate | undefined
): GpsCoordinate | undefined {
  if (coordinate) {
    return {
      lat: coordinate.getLat(),
      lon: coordinate.getLon(),
    };
  }
  return undefined;
}

export function pbLocation(
  location: Location | undefined
): UserLocation | undefined {
  if (location) {
    return {
      village: location.getVillage(),
      parish: location.getParish(),
      sub_county: location.getSubCounty(),
      county: location.getCounty(),
      district: location.getDistrict(),
    };
  }
  return undefined;
}
