import { Produce } from "@/store/modules/produce/produce.types";
import { pbShortUserProfile } from "@/types/types";
import { grpc } from "@improbable-eng/grpc-web";
import { GetProduceResponse } from "acard-protos/js/v1/produce/produce_pb";
import { ProduceServiceClient } from "acard-protos/js/v1/produce/produce_service_pb_service";
import { GetProducesRequest } from "acard-protos/js/v1/produce/rpc_get_produces_pb";

const producesClient = new ProduceServiceClient(process.env.VUE_APP_API_URL);

export function getProduces(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<{ produces: Produce[]; pages: number }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const producesRequest = new GetProducesRequest();
    producesRequest.setPage(page);
    producesRequest.setLimit(offset);
    producesRequest.setQuery(query);

    producesClient.getProduces(producesRequest, metaData, (error, response) => {
      if (error != null) {
        reject(error?.message ?? "Error loading produces");
      } else {
        if (response) {
          resolve({
            produces: pbAgroProducesToProduces(response.getProducesList()),
            pages: response.getPages(),
          });
        } else {
          reject("no");
        }
      }
    });
  });
}

export async function addProduce(authKey: string): Promise<void> {
  // TODO - Add produce
}

export default { getProduces };

function pbAgroProducesToProduces(
  pbAgroProduces: GetProduceResponse[]
): Produce[] {
  return pbAgroProduces.map(pbAgroProduceToPb);
}

function pbAgroProduceToPb(produce: GetProduceResponse): Produce {
  return {
    id: produce.getId(),
    crop: produce.getCrop(),
    quantity: produce.getQuantity(),
    unit: produce.getUnit(),
    user: pbShortUserProfile(produce.getUser()),
    agent: pbShortUserProfile(produce.getAgent()),
    price: produce.getPrice(),
    moisture: produce.getMoisture(),
    produce_type: produceTypeToString(produce.getProduceType()),
    created_at: produce.getCreatedAt()?.toDate() ?? new Date(),
  };
}

function produceTypeToString(type: 0 | 1 | 2): string {
  switch (type) {
    case 1:
      return "FAQ";
    case 2:
      return "KIBOKO";
    default:
      return "";
  }
}
