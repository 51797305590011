import farmerService from "@/services/farmer.service";
import { DefaultState } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { RootTypes } from "../root.types";
import {
  AddCoffeeFarmer,
  AddFarmerPayload,
  addFarmerPayloadToRequest,
  castToAddCoffeeFarmerReq,
  Farmer,
  FarmerTypes,
  FarmerUserProfile,
} from "./farmers.types";

@Module({ namespaced: true })
class FarmerModule extends VuexModule {
  public farmers: FarmerUserProfile[] = [];

  public loadingFarmer = false;

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public addFarmerRef = 0;

  public add: DefaultState = Object.assign({}, this.defaultState);

  public delete: DefaultState = Object.assign({}, this.defaultState);

  public update: DefaultState = Object.assign({}, this.defaultState);

  public upload: DefaultState = Object.assign({}, this.defaultState);

  // Load Farmer
  @Mutation
  public [FarmerTypes.SET_ADD_FARMER_DIALOG_REF](ref: number): void {
    this.addFarmerRef = ref;
  }

  // Load Farmer
  @Mutation
  public [FarmerTypes.SET_LOADING_FARMER](isLoadingFarmer: boolean): void {
    this.loadingFarmer = isLoadingFarmer;
  }

  // Upload Input
  @Mutation
  public [FarmerTypes.SET_UPLOAD_COFFEE_FARMER_DIALOG](
    isUploadingFarmerDialog: boolean
  ): void {
    this.upload.dialog = isUploadingFarmerDialog;
  }

  @Mutation
  public [FarmerTypes.SET_UPLOAD_COFFEE_FARMER_LOADING](
    isUploadingFarmerLoading: boolean
  ): void {
    this.upload.loading = isUploadingFarmerLoading;
  }

  @Mutation
  public [FarmerTypes.SET_UPLOAD_COFFEE_FARMER_ERROR](
    uploadError: string
  ): void {
    this.upload.error.errorMessage = uploadError;
    this.upload.error.error = true;
  }

  // Add Farmer
  @Mutation
  public [FarmerTypes.SET_ADD_FARMER_DIALOG](
    isAddingFarmerDialog: boolean
  ): void {
    this.add.dialog = isAddingFarmerDialog;
  }

  @Mutation
  public [FarmerTypes.INSERT_FARMERS](farmers: FarmerUserProfile[]): void {
    this.farmers.splice(0, this.farmers.length);
    this.farmers.push(...farmers);
  }

  @Mutation
  public [FarmerTypes.SET_ADD_FARMER_LOADING](
    isAddingFarmerLoading: boolean
  ): void {
    this.add.loading = isAddingFarmerLoading;
  }

  @Mutation
  public [FarmerTypes.SET_ADD_FARMER_ERROR](addError: string): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Farmer
  @Mutation
  public [FarmerTypes.SET_DELETE_FARMER_DIALOG](
    isDeletingFarmerDialog: boolean
  ): void {
    this.delete.dialog = isDeletingFarmerDialog;
  }

  @Mutation
  public [FarmerTypes.SET_DELETE_FARMER_LOADING](
    isDeletingFarmerLoading: boolean
  ): void {
    this.delete.loading = isDeletingFarmerLoading;
  }

  @Mutation
  public [FarmerTypes.SET_DELETE_FARMER_ERROR](deleteError: string): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Update Farmer
  @Mutation
  public [FarmerTypes.SET_UPDATE_FARMER_DIALOG](
    isUpdatingFarmerDialog: boolean
  ): void {
    this.update.dialog = isUpdatingFarmerDialog;
  }

  @Mutation
  public [FarmerTypes.SET_UPDATE_FARMER_LOADING](
    isUpdatingFarmerLoading: boolean
  ): void {
    this.update.loading = isUpdatingFarmerLoading;
  }

  @Mutation
  public [FarmerTypes.SET_UPDATE_FARMER_ERROR](updateError: string): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Farmer
  @Mutation
  public [FarmerTypes.INSERT_FARMER](farmer: FarmerUserProfile): void {
    const index = this.farmers.map((x) => x.id).indexOf(farmer.id);

    if (index > -1) {
      this.farmers.splice(index, 1, farmer);
    } else {
      this.farmers.splice(0, 0, farmer);
    }
  }

  // Remove Farmer
  @Mutation
  public [FarmerTypes.REMOVE_FARMER](farmer: FarmerUserProfile): void {
    const index = this.farmers.map((x) => x.id).indexOf(farmer.id);

    if (index > -1) {
      this.farmers.splice(index);
    }
  }

  // Load Farmers
  @Action
  public async [FarmerTypes.LOAD_FARMERS](): Promise<void> {
    this.context.commit(FarmerTypes.SET_LOADING_FARMER, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const farmers = await farmerService.getFarmers(authHeader);
      if (farmers) this.context.commit(FarmerTypes.INSERT_FARMERS, farmers);
    } catch (e) {
      // console.log(e);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading farmers" },
        { root: true }
      );
    } finally {
      this.context.commit(FarmerTypes.SET_LOADING_FARMER, false);
    }
  }
  // Add Farmer
  @Action
  public async [FarmerTypes.ADD_FARMER](
    farmer: AddFarmerPayload
  ): Promise<void> {
    this.context.commit(FarmerTypes.SET_ADD_FARMER_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const addFarmerReq = addFarmerPayloadToRequest(farmer);
      const farmerResult = await farmerService.addFarmer(
        authHeader,
        addFarmerReq
      );

      this.context.commit(FarmerTypes.INSERT_FARMER, farmerResult);
      this.context.commit(FarmerTypes.SET_ADD_FARMER_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Farmer Saved", color: "success" },
        { root: true }
      );
      this.context.commit(
        FarmerTypes.SET_ADD_FARMER_DIALOG_REF,
        this.addFarmerRef + 1
      );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add farmer" },
        { root: true }
      );
    } finally {
      this.context.commit(FarmerTypes.SET_ADD_FARMER_LOADING, false);
    }
  }

  // Delete Farmer
  @Action
  public async [FarmerTypes.DELETE_FARMER](farmer: Farmer): Promise<void> {
    this.context.commit(FarmerTypes.SET_DELETE_FARMER_LOADING, true);

    try {
      const fm = await farmerService.deleteFarmer(farmer);
      this.context.commit(FarmerTypes.REMOVE_FARMER, fm);
      this.context.commit(FarmerTypes.SET_DELETE_FARMER_DIALOG, false);
    } catch (e) {
      this.context.commit(
        FarmerTypes.SET_DELETE_FARMER_ERROR,
        "Failed to delete farmer"
      );
    } finally {
      this.context.commit(FarmerTypes.SET_DELETE_FARMER_LOADING, false);
    }
  }

  // Update Farmer
  @Action
  public async [FarmerTypes.UPDATE_FARMER](farmer: Farmer): Promise<void> {
    this.context.commit(FarmerTypes.SET_UPDATE_FARMER_LOADING, true);

    try {
      const fm = await farmerService.updateFarmer(farmer);
      this.context.commit(FarmerTypes.ADD_FARMER, fm);
      this.context.commit(FarmerTypes.SET_UPDATE_FARMER_DIALOG, false);
    } catch (e) {
      this.context.commit(
        FarmerTypes.SET_UPDATE_FARMER_ERROR,
        "Failed to update farmer"
      );
    } finally {
      this.context.commit(FarmerTypes.SET_UPDATE_FARMER_LOADING, false);
    }
  }

  // Upload Input
  @Action
  public async [FarmerTypes.UPLOAD_COFFEE_FARMERS](
    inputs: AddCoffeeFarmer[]
  ): Promise<void> {
    this.context.commit(FarmerTypes.SET_UPLOAD_COFFEE_FARMER_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const result = await farmerService.addCoffeeFarmers(
        authHeader,
        inputs.map(castToAddCoffeeFarmerReq)
      );
      // this.context.commit(InputTypes.ADD_INPUT, fm);
      this.context.commit(FarmerTypes.SET_UPLOAD_COFFEE_FARMER_DIALOG, false);
      if (result.count == 0) {
        this.context.commit(
          RootTypes.openSnackbar,
          { message: `Failed to upload any records`, color: "error" },
          { root: true }
        );
      } else {
        this.context.commit(
          RootTypes.openSnackbar,
          { message: `Uploaded ${result.count} records`, color: "success" },
          { root: true }
        );
      }
      // TODO: Handle errors
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to upload farmers" },
        { root: true }
      );
    } finally {
      this.context.commit(FarmerTypes.SET_UPLOAD_COFFEE_FARMER_LOADING, false);
    }
  }
}

export default FarmerModule;
