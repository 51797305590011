import { DefaultState } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AddDealerPayload,
  addDealerPayloadToRequest,
  Dealer,
  DealerTypes,
  DealerUserProfile,
} from "./dealer.types";
import dealerService from "@/services/dealer.service";
import { RootTypes } from "../root.types";

@Module({ namespaced: true })
class DealerModule extends VuexModule {
  public dealers: DealerUserProfile[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public add: DefaultState = this.defaultState;

  public delete: DefaultState = this.defaultState;

  public update: DefaultState = this.defaultState;

  public loadingDealer = false;

  // Load Dealer
  @Mutation
  public [DealerTypes.SET_LOADING_DEALER](isLoadingDealer: boolean): void {
    this.loadingDealer = isLoadingDealer;
  }

  // Add Dealer
  @Mutation
  public [DealerTypes.SET_ADD_DEALER_DIALOG](
    isAddingDealerDialog: boolean
  ): void {
    this.add.dialog = isAddingDealerDialog;
  }

  @Mutation
  public [DealerTypes.INSERT_DEALERS](dealers: DealerUserProfile[]): void {
    this.dealers.splice(0, this.dealers.length);
    this.dealers.push(...dealers);
  }

  @Mutation
  public [DealerTypes.SET_ADD_DEALER_LOADING](
    isAddingDealerLoading: boolean
  ): void {
    this.add.loading = isAddingDealerLoading;
  }

  @Mutation
  public [DealerTypes.SET_ADD_DEALER_ERROR](addError: string): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Dealer
  @Mutation
  public [DealerTypes.SET_DELETE_DEALER_DIALOG](
    isDeletingDealerDialog: boolean
  ): void {
    this.delete.dialog = isDeletingDealerDialog;
  }

  @Mutation
  public [DealerTypes.SET_DELETE_DEALER_LOADING](
    isDeletingDealerLoading: boolean
  ): void {
    this.delete.loading = isDeletingDealerLoading;
  }

  @Mutation
  public [DealerTypes.SET_DELETE_DEALER_ERROR](deleteError: string): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Update Dealer
  @Mutation
  public [DealerTypes.SET_UPDATE_DEALER_DIALOG](
    isUpdatingDealerDialog: boolean
  ): void {
    this.update.dialog = isUpdatingDealerDialog;
  }

  @Mutation
  public [DealerTypes.SET_UPDATE_DEALER_LOADING](
    isUpdatingDealerLoading: boolean
  ): void {
    this.update.loading = isUpdatingDealerLoading;
  }

  @Mutation
  public [DealerTypes.SET_UPDATE_DEALER_ERROR](updateError: string): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Dealer
  @Mutation
  public [DealerTypes.INSERT_DEALER](dealer: DealerUserProfile): void {
    const index = this.dealers.map((x) => x.id).indexOf(dealer.id);

    if (index > -1) {
      this.dealers.splice(index, 1, dealer);
    } else {
      this.dealers.splice(0, 0, dealer);
    }
  }

  // Remove Dealer
  @Mutation
  public [DealerTypes.REMOVE_DEALER](dealer: DealerUserProfile): void {
    const index = this.dealers.map((x) => x.id).indexOf(dealer.id);

    if (index > -1) {
      this.dealers.splice(index);
    }
  }

  // Load Dealers
  @Action
  public async [DealerTypes.LOAD_DEALERS](): Promise<void> {
    this.context.commit(DealerTypes.SET_LOADING_DEALER, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const dealers = await dealerService.getDealers(authHeader);

      if (dealers) this.context.commit(DealerTypes.INSERT_DEALERS, dealers);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading dealers" },
        { root: true }
      );
    } finally {
      this.context.commit(DealerTypes.SET_LOADING_DEALER, false);
    }
  }
  // Add Dealer
  @Action
  public async [DealerTypes.ADD_DEALER](
    dealer: AddDealerPayload
  ): Promise<void> {
    this.context.commit(DealerTypes.SET_ADD_DEALER_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const addDealerReq = addDealerPayloadToRequest(dealer);
      const dealerResult = await dealerService.addDealer(
        authHeader,
        addDealerReq
      );
      this.context.commit(DealerTypes.INSERT_DEALER, dealerResult);
      this.context.commit(DealerTypes.SET_ADD_DEALER_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Dealer Saved", color: "success" },
        { root: true }
      );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add dealer" },
        { root: true }
      );
    } finally {
      this.context.commit(DealerTypes.SET_ADD_DEALER_LOADING, false);
    }
  }

  // Delete Dealer
  @Action
  public async [DealerTypes.DELETE_DEALER](dealer: Dealer): Promise<void> {
    this.context.commit(DealerTypes.SET_DELETE_DEALER_LOADING, true);

    try {
      const fm = await dealerService.deleteDealer(dealer);
      this.context.commit(DealerTypes.REMOVE_DEALER, fm);
      this.context.commit(DealerTypes.SET_DELETE_DEALER_DIALOG, false);
    } catch (e) {
      this.context.commit(
        DealerTypes.SET_DELETE_DEALER_ERROR,
        "Failed to delete dealer"
      );
    } finally {
      this.context.commit(DealerTypes.SET_DELETE_DEALER_LOADING, false);
    }
  }

  // Update Dealer
  @Action
  public async [DealerTypes.UPDATE_DEALER](dealer: Dealer): Promise<void> {
    this.context.commit(DealerTypes.SET_UPDATE_DEALER_LOADING, true);

    try {
      const fm = await dealerService.updateDealer(dealer);
      this.context.commit(DealerTypes.ADD_DEALER, fm);
      this.context.commit(DealerTypes.SET_UPDATE_DEALER_DIALOG, false);
    } catch (e) {
      this.context.commit(
        DealerTypes.SET_UPDATE_DEALER_ERROR,
        "Failed to update dealer"
      );
    } finally {
      this.context.commit(DealerTypes.SET_UPDATE_DEALER_LOADING, false);
    }
  }
}

export default DealerModule;
