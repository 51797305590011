import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/auth/auth.module";
import Traceability from "./modules/traceability/traceability.module";
import Stats from "./modules/stats/stats.module";
import Farmers from "./modules/farmers/farmers.module";
import Dealer from "./modules/dealer/dealer.module";
import Enterprise from "./modules/enterprise/enterprise.module";
import Input from "./modules/input/input.module";
import Produce from "./modules/produce/produce.module";
import Order from "./modules/order/order.module";
import Card from "./modules/card/card.module";
import { state as RootState } from "@/store/modules/root.state";
import RootMutation from "@/store/modules/root.mutations";
import { TraceabilityTypes } from "./modules/traceability/traceability.types";

Vue.use(Vuex);

export default new Vuex.Store({
  state: RootState,
  mutations: RootMutation,
  actions: {},
  modules: {
    Stats,
    Auth,
    Farmers,
    Dealer,
    Enterprise,
    Input,
    Produce,
    Order,
    Card,
    [TraceabilityTypes.MODULE]: Traceability,
  },
});
