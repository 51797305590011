import { DefaultState, Pagination } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { AddInput, Input, InputTypes } from "./input.types";
import inputService from "@/services/input.service";
import { RootTypes } from "../root.types";

@Module({ namespaced: true })
class InputModule extends VuexModule {
  public inputs: AddInput[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public add: DefaultState = this.defaultState;

  public delete: DefaultState = this.defaultState;

  public update: DefaultState = this.defaultState;

  public upload: DefaultState = this.defaultState;

  public loadingInput = false;

  // Set input pages count
  @Mutation
  public [InputTypes.SET_INPUT_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Input
  @Mutation
  public [InputTypes.SET_LOADING_INPUT](isLoadingInput: boolean): void {
    this.loadingInput = isLoadingInput;
  }

  // Add Input
  @Mutation
  public [InputTypes.SET_ADD_INPUT_DIALOG](isAddingInputDialog: boolean): void {
    this.add.dialog = isAddingInputDialog;
  }

  @Mutation
  public [InputTypes.INSERT_INPUTS](inputs: AddInput[]): void {
    this.inputs.splice(0, this.inputs.length);
    this.inputs.push(...inputs);
  }

  @Mutation
  public [InputTypes.SET_ADD_INPUT_LOADING](
    isAddingInputLoading: boolean
  ): void {
    this.add.loading = isAddingInputLoading;
  }

  @Mutation
  public [InputTypes.SET_ADD_INPUT_ERROR](addError: string): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Input
  @Mutation
  public [InputTypes.SET_DELETE_INPUT_DIALOG](
    isDeletingInputDialog: boolean
  ): void {
    this.delete.dialog = isDeletingInputDialog;
  }

  @Mutation
  public [InputTypes.SET_DELETE_INPUT_LOADING](
    isDeletingInputLoading: boolean
  ): void {
    this.delete.loading = isDeletingInputLoading;
  }

  @Mutation
  public [InputTypes.SET_DELETE_INPUT_ERROR](deleteError: string): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Upload Input
  @Mutation
  public [InputTypes.SET_UPLOAD_INPUT_DIALOG](
    isUploadingInputDialog: boolean
  ): void {
    this.upload.dialog = isUploadingInputDialog;
  }

  @Mutation
  public [InputTypes.SET_UPLOAD_INPUT_LOADING](
    isUploadingInputLoading: boolean
  ): void {
    this.upload.loading = isUploadingInputLoading;
  }

  @Mutation
  public [InputTypes.SET_UPLOAD_INPUT_ERROR](uploadError: string): void {
    this.upload.error.errorMessage = uploadError;
    this.upload.error.error = true;
  }

  // Update Input
  @Mutation
  public [InputTypes.SET_UPDATE_INPUT_DIALOG](
    isUpdatingInputDialog: boolean
  ): void {
    this.update.dialog = isUpdatingInputDialog;
  }

  @Mutation
  public [InputTypes.SET_UPDATE_INPUT_LOADING](
    isUpdatingInputLoading: boolean
  ): void {
    this.update.loading = isUpdatingInputLoading;
  }

  @Mutation
  public [InputTypes.SET_UPDATE_INPUT_ERROR](updateError: string): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Input
  @Mutation
  public [InputTypes.INSERT_INPUT](input: AddInput): void {
    const index = this.inputs.map((x) => x.name).indexOf(input.name);

    if (index > -1) {
      this.inputs.splice(index, 1, input);
    } else {
      this.inputs.splice(0, 0, input);
    }
  }

  // Remove Input
  @Mutation
  public [InputTypes.REMOVE_INPUT](input: Input): void {
    const index = this.inputs.map((x) => x.name).indexOf(input.name);

    if (index > -1) {
      this.inputs.splice(index);
    }
  }

  // Load Inputs
  @Action
  public async [InputTypes.LOAD_INPUTS]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    this.context.commit(InputTypes.SET_LOADING_INPUT, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const { inputs, pages } = await inputService.getInputs(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(InputTypes.INSERT_INPUTS, inputs);
      this.context.commit(InputTypes.SET_INPUT_PAGES, pages);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading inputs" },
        { root: true }
      );
    } finally {
      this.context.commit(InputTypes.SET_LOADING_INPUT, false);
    }
  }
  // Add Input
  @Action
  public async [InputTypes.ADD_INPUT](input: Input): Promise<void> {
    this.context.commit(InputTypes.SET_ADD_INPUT_LOADING, true);

    try {
      // const fm = await inputService.addInput(input);
      // this.context.commit(InputTypes.INSERT_INPUT, fm);
      // this.context.commit(InputTypes.SET_ADD_INPUT_DIALOG, false);
      // this.context.commit(
      //   RootTypes.openSnackbar,
      //   { message: "Input Saved", color: "success" },
      //   { root: true }
      // );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add input" },
        { root: true }
      );
    } finally {
      this.context.commit(InputTypes.SET_ADD_INPUT_LOADING, false);
    }
  }

  // Delete Input
  @Action
  public async [InputTypes.DELETE_INPUT](input: Input): Promise<void> {
    this.context.commit(InputTypes.SET_DELETE_INPUT_LOADING, true);

    try {
      // const fm = await inputService.deleteInput(input);
      // this.context.commit(InputTypes.REMOVE_INPUT, fm);
      // this.context.commit(InputTypes.SET_DELETE_INPUT_DIALOG, false);
    } catch (e) {
      this.context.commit(
        InputTypes.SET_DELETE_INPUT_ERROR,
        "Failed to delete input"
      );
    } finally {
      this.context.commit(InputTypes.SET_DELETE_INPUT_LOADING, false);
    }
  }

  // Update Input
  @Action
  public async [InputTypes.UPDATE_INPUT](input: Input): Promise<void> {
    this.context.commit(InputTypes.SET_UPDATE_INPUT_LOADING, true);

    try {
      // const fm = await inputService.updateInput(input);
      // this.context.commit(InputTypes.ADD_INPUT, fm);
      // this.context.commit(InputTypes.SET_UPDATE_INPUT_DIALOG, false);
    } catch (e) {
      this.context.commit(
        InputTypes.SET_UPDATE_INPUT_ERROR,
        "Failed to update input"
      );
    } finally {
      this.context.commit(InputTypes.SET_UPDATE_INPUT_LOADING, false);
    }
  }

  // Upload Input
  @Action
  public async [InputTypes.UPLOAD_INPUTS](inputs: AddInput[]): Promise<void> {
    this.context.commit(InputTypes.SET_UPLOAD_INPUT_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const result = await inputService.addInputs(authHeader, inputs);
      // this.context.commit(InputTypes.ADD_INPUT, fm);
      this.context.commit(InputTypes.SET_UPLOAD_INPUT_DIALOG, false);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: `Uploaded ${result.count} records`, color: "success" },
        { root: true }
      );
      // TODO: Handle errors
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to upload inputs" },
        { root: true }
      );
    } finally {
      this.context.commit(InputTypes.SET_UPLOAD_INPUT_LOADING, false);
    }
  }
}

export default InputModule;
