import { base64ToBlob, base64ToImage } from "@/mixins/files";
import { DefaultState } from "@/types/types";
import { Gender } from "acard-protos/js/meta/meta_pb";
import { AddDealerRequest } from "acard-protos/js/v1/dealer/rpc_add_dealer_pb";
import { CardRequest, CardType } from "acard-protos/js/v1/user/card_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

export type Dealer = {
  id?: string;
  first_name: string;
  last_name: string;
  other_names?: string;
  phone_no: string;
  registration_no?: string;
  dob: string;
  photo?: string;
  card: {
    type: "national-id" | "driving-permit";
    no?: string;
    front?: string;
    back?: string;
  };
  gender: "M" | "F";
  location: {
    district: string;
    county: string;
    sub_county: string;
    parish: string;
    village: string;
  };
};

export type AddDealerPayload = {
  first_name: string;
  last_name: string;
  other_names?: string;
  phone_no: string;
  dob: string;
  photo: string;
  card: {
    type: "national-id" | "driving-permit";
    no: string;
    front: string;
    back: string;
  };
  gender: "M" | "F";
  village: number;
};

export type DealerUserProfile = {
  id: number;
  name: string;
  phone: string;
  photo: string;
  village: string;
  farmers: number;
  district: string;
  createdAt: Date;
  updatedAt?: Date;
};

export type DealersState = {
  dealers: Dealer[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export enum DealerTypes {
  // Actions
  LOAD_DEALERS = "LOAD_DEALERS",
  ADD_DEALER = "ADD_DEALER",
  DELETE_DEALER = "DELETE_DEALER",
  UPDATE_DEALER = "UPDATE_DEALER",

  // Mutations
  SET_LOADING_DEALER = "SET_LOADING_DEALER",

  INSERT_DEALER = "INSERT_DEALER",
  INSERT_DEALERS = "INSERT_DEALERS",
  REMOVE_DEALER = "REMOVE_DEALER",

  SET_ADD_DEALER_LOADING = "SET_ADD_DEALER_LOADING",
  SET_ADD_DEALER_DIALOG = "SET_ADD_DEALER_DIALOG",
  SET_ADD_DEALER_ERROR = "SET_ADD_DEALER_ERROR",

  SET_UPDATE_DEALER_LOADING = "SET_UPDATE_DEALER_LOADING",
  SET_UPDATE_DEALER_DIALOG = "SET_UPDATE_DEALER_DIALOG",
  SET_UPDATE_DEALER_ERROR = "SET_UPDATE_DEALER_ERROR",

  SET_DELETE_DEALER_LOADING = "SET_DELETE_DEALER_LOADING",
  SET_DELETE_DEALER_DIALOG = "SET_DELETE_DEALER_DIALOG",
  SET_DELETE_DEALER_ERROR = "SET_DELETE_DEALER_ERROR",
}

export function dealerToFormData(dealer: Dealer): FormData {
  const data = new FormData();

  if (dealer.photo)
    data.append("photo", base64ToBlob(dealer.photo), "photo.png");
  if (dealer.card.front)
    data.append("front", base64ToBlob(dealer.card.front), "front.png");
  if (dealer.card.back)
    data.append("back", base64ToBlob(dealer.card.back), "back.png");

  const json = Object.assign({}, dealer);
  json.phone_no = `+256${json.phone_no}`;
  delete json.photo;
  delete json.card.front;
  delete json.card.back;
  data.append("json", JSON.stringify(json));

  return data;
}

export function addDealerPayloadToRequest(
  payload: AddDealerPayload
): AddDealerRequest {
  const request = new AddDealerRequest();

  request.setFirstName(payload.first_name);
  request.setLastName(payload.last_name);
  request.setOtherNames(payload.other_names ?? "");
  request.setDateOfBirth(Timestamp.fromDate(new Date(payload.dob)));
  request.setGender(stringToPbGender(payload.gender));
  request.setPhone(payload.phone_no);
  request.setPhoto(base64ToImage(payload.photo));
  request.setVillage(payload.village);

  // Card
  const cardRequest = new CardRequest();
  cardRequest.setNo(payload.card.no);
  cardRequest.setFront(base64ToImage(payload.card.front));
  cardRequest.setBack(base64ToImage(payload.card.back));
  cardRequest.setType(stringToPbCardType(payload.card.type));
  request.setCard(cardRequest);

  return request;
}

export function stringToPbGender(gender: string): 0 | 1 | 2 {
  switch (gender) {
    case "M":
      return Gender.GENDER_MALE;
    case "F":
      return Gender.GENDER_FEMALE;
    default:
      return Gender.GENDER_UNSPECIFIED;
  }
}

export function pbGenderToString(gender: 0 | 1 | 2): "M" | "F" | null {
  switch (gender) {
    case 1:
      return "M";
    case 2:
      return "F";
    default:
      return null;
  }
}

export function stringToPbCardType(cardType: string): 0 | 1 | 2 {
  switch (cardType) {
    case "national-id":
      return CardType.CARD_TYPE_NATIONAL_ID;
    case "driving-permit":
      return CardType.CARD_TYPE_DRIVING_LICENSE;
    default:
      return CardType.CARD_TYPE_UNSPECIFIED;
  }
}
