import { DefaultState, JsAgentProfile, JsFarmerProfile } from "@/types/types";

export type Order = {
  id: number;
  items: OrderItem[];
  sub_total: number;
  processing_fee: number;
  tax: number;
  amount: number;
  status: string;
  farmer?: JsFarmerProfile;
  agent?: JsAgentProfile;
  created_at: Date;
  updated_at?: Date;
};

export type OrderItem = {
  id: number;
  name: string;
  batch_no: string;
  quantity: number;
  unit_price: number;
  category: string;
};

export type OrdersState = {
  orders: Order[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export enum OrderTypes {
  // Actions
  LOAD_ORDERS = "LOAD_ORDERS",
  ADD_ORDER = "ADD_ORDER",
  DELETE_ORDER = "DELETE_ORDER",
  UPDATE_ORDER = "UPDATE_ORDER",

  // Mutations
  SET_LOADING_ORDER = "SET_LOADING_ORDER",

  INSERT_ORDER = "INSERT_ORDER",
  INSERT_ORDERS = "INSERT_ORDERS",
  REMOVE_ORDER = "REMOVE_ORDER",

  SET_ORDER_PAGES = "SET_ORDER_PAGES",

  SET_ADD_ORDER_LOADING = "SET_ADD_ORDER_LOADING",
  SET_ADD_ORDER_DIALOG = "SET_ADD_ORDER_DIALOG",
  SET_ADD_ORDER_ERROR = "SET_ADD_ORDER_ERROR",

  SET_UPDATE_ORDER_LOADING = "SET_UPDATE_ORDER_LOADING",
  SET_UPDATE_ORDER_DIALOG = "SET_UPDATE_ORDER_DIALOG",
  SET_UPDATE_ORDER_ERROR = "SET_UPDATE_ORDER_ERROR",

  SET_DELETE_ORDER_LOADING = "SET_DELETE_ORDER_LOADING",
  SET_DELETE_ORDER_DIALOG = "SET_DELETE_ORDER_DIALOG",
  SET_DELETE_ORDER_ERROR = "SET_DELETE_ORDER_ERROR",
}

export function pbCategoryToString(
  category: 0 | 1 | 2 | 3 | 4 | 5 | 6
): string {
  switch (category) {
    case 1:
      return "HERBICIDES";

    case 2:
      return "INSECTICIDES";

    case 3:
      return "ORGANICS";

    case 4:
      return "FUNGICIDES";

    case 5:
      return "PUMPS";

    case 6:
      return "OTHERS";
    default:
      return "UNSPECIFIED";
  }
}

export function pbStatusToString(status: 0 | 1 | 2): string {
  switch (status) {
    case 1:
      return "COMPLETE";

    case 2:
      return "PENDING";
    default:
      return "UNSPECIFIED";
  }
}
