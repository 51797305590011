import {
  Order,
  OrderItem,
  pbCategoryToString,
  pbStatusToString,
} from "@/store/modules/order/order.types";
import { pbAgentProfile, pbFarmerProfile } from "@/types/types";
import { grpc } from "@improbable-eng/grpc-web";
import { InputOrder } from "acard-protos/js/v1/order/order_pb";
import { OrderServiceClient } from "acard-protos/js/v1/order/order_service_pb_service";
import { GetOrdersRequest } from "acard-protos/js/v1/order/rpc_get_orders_pb";

const ordersClient = new OrderServiceClient(process.env.VUE_APP_API_URL);

export function getOrders(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<{ orders: Order[]; pages: number }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const ordersRequest = new GetOrdersRequest();
    ordersRequest.setPage(page);
    ordersRequest.setLimit(offset);
    ordersRequest.setQuery(query);

    ordersClient.getOrders(ordersRequest, metaData, (error, response) => {
      if (error != null) {
        reject(error?.message ?? "Error loading orders");
      } else {
        if (response) {
          resolve({
            orders: pbAgroOrdersToOrders(response.getOrdersList()),
            pages: response.getPages(),
          });
        } else {
          reject("no");
        }
      }
    });
  });
}

export async function addOrder(authKey: string): Promise<void> {
  // TODO - Add order
}

export default { getOrders };

function pbAgroOrdersToOrders(pbAgroOrders: InputOrder[]): Order[] {
  return pbAgroOrders.map(pbAgroOrderToPb);
}

function pbAgroOrderToPb(order: InputOrder): Order {
  return {
    id: order.getId(),
    sub_total: order.getSubTotal(),
    processing_fee: order.getProcessingFee(),
    tax: order.getTax(),
    amount: order.getAmount(),
    items: order.getItemsList().map(function (item) {
      return <OrderItem>{
        id: item.getId(),
        name: item.getName(),
        batch_no: item.getBatchNo(),
        quantity: item.getQuantity(),
        unit_price: item.getUnitPrice(),
        category: pbCategoryToString(item.getCategory()),
      };
    }),
    farmer: pbFarmerProfile(order.getFarmer()),
    agent: pbAgentProfile(order.getDealer()),
    status: pbStatusToString(order.getStatus()),
    created_at: order.getCreatedAt()?.toDate() ?? new Date(),
    updated_at: order.getUpdatedAt()?.toDate(),
  };
}
