import { DefaultState } from "@/types/types";

export type AgroEnterprise = {
  id: number;
  name: string;
  created_at: Date;
  updated_at?: Date;
};

export type EnterpriseVariety = {
  id: number;
  name: string;
  created_at: Date;
  updated_at?: Date;
};

export type EnterpriseWithVarieties = {
  id: number;
  name: string;
  varieties: EnterpriseVariety[];
  created_at: Date;
  updated_at?: Date;
};

export type EnterprisesState = {
  enterprises: AgroEnterprise[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export enum EnterpriseTypes {
  // Actions
  LOAD_ENTERPRISES = "LOAD_ENTERPRISES",
  SEARCH_ENTERPRISES = "SEARCH_ENTERPRISES",
  ADD_ENTERPRISE = "ADD_ENTERPRISE",
  DELETE_ENTERPRISE = "DELETE_ENTERPRISE",
  UPDATE_ENTERPRISE = "UPDATE_ENTERPRISE",

  // Mutations
  SET_LOADING_ENTERPRISE = "SET_LOADING_ENTERPRISE",

  INSERT_ENTERPRISE = "INSERT_ENTERPRISE",
  INSERT_ENTERPRISES = "INSERT_ENTERPRISES",
  REMOVE_ENTERPRISE = "REMOVE_ENTERPRISE",

  SET_LOADING_SEARCH_ENTERPRISE = "SET_LOADING_SEARCH_ENTERPRISE",

  INSERT_SEARCH_ENTERPRISE = "INSERT_SEARCH_ENTERPRISE",
  INSERT_SEARCH_ENTERPRISES = "INSERT_SEARCH_ENTERPRISES",
  REMOVE_SEARCH_ENTERPRISE = "REMOVE_SEARCH_ENTERPRISE",

  SET_ENTERPRISE_PAGES = "SET_ENTERPRISE_PAGES",

  SET_ADD_ENTERPRISE_LOADING = "SET_ADD_ENTERPRISE_LOADING",
  SET_ADD_ENTERPRISE_DIALOG = "SET_ADD_ENTERPRISE_DIALOG",
  SET_ADD_ENTERPRISE_ERROR = "SET_ADD_ENTERPRISE_ERROR",

  SET_UPDATE_ENTERPRISE_LOADING = "SET_UPDATE_ENTERPRISE_LOADING",
  SET_UPDATE_ENTERPRISE_DIALOG = "SET_UPDATE_ENTERPRISE_DIALOG",
  SET_UPDATE_ENTERPRISE_ERROR = "SET_UPDATE_ENTERPRISE_ERROR",

  SET_DELETE_ENTERPRISE_LOADING = "SET_DELETE_ENTERPRISE_LOADING",
  SET_DELETE_ENTERPRISE_DIALOG = "SET_DELETE_ENTERPRISE_DIALOG",
  SET_DELETE_ENTERPRISE_ERROR = "SET_DELETE_ENTERPRISE_ERROR",
}

export function pbCategoryToString(
  category: 0 | 1 | 2 | 3 | 4 | 5 | 6
): string {
  switch (category) {
    case 1:
      return "HERBICIDES";

    case 2:
      return "INSECTICIDES";

    case 3:
      return "ORGANICS";

    case 4:
      return "FUNGICIDES";

    case 5:
      return "PUMPS";

    case 6:
      return "OTHERS";
    default:
      return "UNSPECIFIED";
  }
}
