import { DefaultState, GpsCoordinate, UserLocation } from "@/types/types";
// import { AddTraceabilityRequest } from "acard-protos/js/v1/traceability/rpc_add_traceability_pb";

export type Traceability = {
  id: string;
  farmer_code: string;
  date_of_registration: Date;
  date_of_first_inspection: Date;
  date_of_last_inspection: Date;
  is_organic_compliant: boolean;
  altitude: number;
  gps_coordinates?: GpsCoordinate;
  acreage_under_construction?: number;
  acreage_other_crops?: number;
  number_coffee_plots: number;
  acreage_for_coffee: number;
  total_land_acreage: number;
  total_coffee_trees: number;
  total_productive_coffee_trees: number;
  parch_per_tree_per_year: number;
  total_yield: number;
  location: UserLocation;
  full_name: string;
  gender: string;
  created_at: Date;
  updated_at?: Date;
};

export type AddTraceability = {
  name: string;
  package: string;
  category: string;
  company: string;
  unit: string;
};

export type TraceabilitiesState = {
  traceabilities: Traceability[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export enum TraceabilityTypes {
  MODULE = "TRACEABILITIES",
  // Actions
  LOAD_TRACEABILITIES = "LOAD_TRACEABILITIES",
  UPLOAD_TRACEABILITIES = "UPLOAD_TRACEABILITIES",
  ADD_TRACEABILITY = "ADD_TRACEABILITY",
  DELETE_TRACEABILITY = "DELETE_TRACEABILITY",
  UPDATE_TRACEABILITY = "UPDATE_TRACEABILITY",

  // Mutations
  SET_LOADING_TRACEABILITY = "SET_LOADING_TRACEABILITY",
  SET_UPLOADING_TRACEABILITY = "SET_UPLOADING_TRACEABILITY",

  INSERT_TRACEABILITY = "INSERT_TRACEABILITY",
  INSERT_TRACEABILITIES = "INSERT_TRACEABILITIES",
  REMOVE_TRACEABILITY = "REMOVE_TRACEABILITY",

  SET_TRACEABILITY_PAGES = "SET_TRACEABILITY_PAGES",

  SET_ADD_TRACEABILITY_LOADING = "SET_ADD_TRACEABILITY_LOADING",
  SET_ADD_TRACEABILITY_DIALOG = "SET_ADD_TRACEABILITY_DIALOG",
  SET_ADD_TRACEABILITY_ERROR = "SET_ADD_TRACEABILITY_ERROR",

  SET_UPDATE_TRACEABILITY_LOADING = "SET_UPDATE_TRACEABILITY_LOADING",
  SET_UPDATE_TRACEABILITY_DIALOG = "SET_UPDATE_TRACEABILITY_DIALOG",
  SET_UPDATE_TRACEABILITY_ERROR = "SET_UPDATE_TRACEABILITY_ERROR",

  SET_DELETE_TRACEABILITY_LOADING = "SET_DELETE_TRACEABILITY_LOADING",
  SET_DELETE_TRACEABILITY_DIALOG = "SET_DELETE_TRACEABILITY_DIALOG",
  SET_DELETE_TRACEABILITY_ERROR = "SET_DELETE_TRACEABILITY_ERROR",

  SET_UPLOAD_TRACEABILITY_LOADING = "SET_UPLOAD_TRACEABILITY_LOADING",
  SET_UPLOAD_TRACEABILITY_DIALOG = "SET_UPLOAD_TRACEABILITY_DIALOG",
  SET_UPLOAD_TRACEABILITY_ERROR = "SET_UPLOAD_TRACEABILITY_ERROR",

  EXPORT_TRACEABILITIES = "EXPORT_TRACEABILITIES",
  EXPORTING_TRACEABILITIES = "EXPORTING_TRACEABILITIES",
  SET_EXPORT_TRACEABILITIES_LOADING = "SET_EXPORT_TRACEABILITIES_LOADING",
}

export function pbCategoryToString(
  category: 0 | 1 | 2 | 3 | 4 | 5 | 6
): string {
  switch (category) {
    case 1:
      return "HERBICIDES";

    case 2:
      return "INSECTICIDES";

    case 3:
      return "ORGANICS";

    case 4:
      return "FUNGICIDES";

    case 5:
      return "PUMPS";

    case 6:
      return "OTHERS";
    default:
      return "UNSPECIFIED";
  }
}

export function stringCategoryToPb(
  category: string
): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  switch (category) {
    case "HERBICIDES":
      return 1;

    case "INSECTICIDES":
      return 2;

    case "ORGANICS":
      return 3;

    case "FUNGICIDES":
      return 4;

    case "PUMPS":
      return 5;

    default:
      return 6;
  }
}

/*
export function addTraceabilityToPb(addTraceability: AddTraceability): AddTraceabilityRequest {
  const req = new AddTraceabilityRequest();

  req.setName(addTraceability.name);
  req.setCategory(stringCategoryToPb(addTraceability.category));
  req.setUnits(addTraceability.unit);
  req.setCompany(addTraceability.company);
  req.setPackage(addTraceability.package);

  return req;
}
*/
