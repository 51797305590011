import {
  FarmerCard,
  pbCategoryToString,
} from "@/store/modules/card/card.types";
import { grpc } from "@improbable-eng/grpc-web";
import { AgroCard } from "acard-protos/js/v1/card/card_pb";
import { CardServiceClient } from "acard-protos/js/v1/card/card_service_pb_service";
import { GetCardsRequest } from "acard-protos/js/v1/card/rpc_get_cards_pb";

const cardsClient = new CardServiceClient(process.env.VUE_APP_API_URL);

export function getCards(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<{ cards: FarmerCard[]; pages: number }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const cardsRequest = new GetCardsRequest();
    cardsRequest.setPage(page);
    cardsRequest.setLimit(offset);
    cardsRequest.setQuery(query);

    cardsClient.getCards(cardsRequest, metaData, (error, response) => {
      if (error != null) {
        reject(error?.message ?? "Error loading cards");
      } else {
        if (response) {
          resolve({
            cards: pbAgroCardsToCards(response.getCardsList()),
            pages: response.getPages(),
          });
        } else {
          reject("no");
        }
      }
    });
  });
}

export async function addCard(authKey: string): Promise<void> {
  // TODO - Add card
}

export default { getCards };

function pbAgroCardsToCards(pbAgroCards: AgroCard[]): FarmerCard[] {
  return pbAgroCards.map(pbAgroCardToPb);
}

function pbAgroCardToPb(card: AgroCard): FarmerCard {
  return {
    id: card.getId(),
    card_no: card.getCardNo(),
    name: card.getUser(),
    created_at: card.getCreatedAt()?.toDate() ?? new Date(),
    updated_at: card.getCreatedAt()?.toDate(),
  };
}
