import { Dealer, DealerUserProfile } from "@/store/modules/dealer/dealer.types";
import { DealerServiceClient } from "acard-protos/js/v1/dealer/dealer_service_pb_service";
import { grpc } from "@improbable-eng/grpc-web";
import { GetDealersRequest } from "acard-protos/js/v1/dealer/rpc_get_dealers_pb";
import { DealerProfile } from "acard-protos/js/v1/user/user_pb";
import { AddDealerRequest } from "acard-protos/js/v1/dealer/rpc_add_dealer_pb";

const dealerService = new DealerServiceClient(process.env.VUE_APP_API_URL);

export function getDealers(
  authKey: string
): Promise<Array<DealerUserProfile> | null> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const getDealersRequest = new GetDealersRequest();

    dealerService.getDealers(getDealersRequest, metaData, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        let dealers: DealerUserProfile[] = [];
        if (response) {
          dealers = dealerProfilesToDealers(response.getDealersList());
        }
        resolve(dealers);
      }
    });
  });
}

export const addDealer = async (
  authKey: string,
  request: AddDealerRequest
): Promise<DealerUserProfile> => {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    dealerService.addDealer(request, metaData, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        if (response) {
          resolve(dealerProfileToDealer(response));
        } else {
          reject("No dealer returned");
        }
      }
    });
  });
};

export const updateDealer = async (farmer: Dealer) => {
  // TODO - Send PUT Request to api
};

export const deleteDealer = async (farmer: Dealer) => {
  // TODO - Send DELETE Request to api
};

export default { getDealers, addDealer, updateDealer, deleteDealer };

function dealerProfilesToDealers(
  dealer_profiles: DealerProfile[]
): DealerUserProfile[] {
  return dealer_profiles.map(dealerProfileToDealer);
}

function dealerProfileToDealer(dealer: DealerProfile) {
  const dealerProfile: DealerUserProfile = {
    id: dealer.getId(),
    name: dealer.getName(),
    photo: dealer.getPhoto(),
    phone: dealer.getPhone(),
    village: dealer.getVillage(),
    district: dealer.getDistrict(),
    farmers: dealer.getFarmers(),
    createdAt: dealer.getCreatedAt()?.toDate() ?? new Date(),
    updatedAt: dealer.getUpdatedAt()?.toDate(),
  };

  return dealerProfile;
}
