
import ValidationMixin from "@/mixins/validation";
import {
  SignByPhonePayload,
  SignInState,
  SignInTypes,
} from "@/store/modules/auth/auth.types";
import { Validator } from "@/types/validator";
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
const Auth = namespace("Auth");
@Component
export default class Login extends Mixins(ValidationMixin) {
  public form: SignByPhonePayload = { phone: "" };
  public code: string | null = null;
  public isValid = false;

  @Auth.State("signIn")
  public signInState!: SignInState;

  @Auth.State("secret")
  public signInSecret!: string | null;

  @Auth.Action(SignInTypes.SIGN_IN)
  private signIn!: (payload: SignByPhonePayload) => Promise<void>;

  @Auth.Action(SignInTypes.VERIFY_SIGN_IN)
  private verifySignIn!: (code: string) => Promise<void>;

  async handleLogin(): Promise<void> {
    await (this.$refs.loginForm as unknown as Validator).validate();

    if (this.isValid) {
      if (this.code) {
        await this.verifySignIn(this.code);
      } else {
        await this.signIn(this.form);
      }
    }
  }
}
