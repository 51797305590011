import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { setUpUtils } from "./mixins/utils";

// Croppie
import VueCroppie from "vue-croppie";
import "croppie/croppie.css";
Vue.use(VueCroppie);

Vue.config.productionTip = false;

setUpUtils(Vue, store);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
