import { DefaultState } from "@/types/types";

export type FarmerCard = {
  id: number;
  card_no: string;
  name: string;
  created_at: Date;
  updated_at?: Date;
};

export type CardsState = {
  cards: FarmerCard[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export enum CardTypes {
  // Actions
  LOAD_CARDS = "LOAD_CARDS",
  ADD_CARD = "ADD_CARD",
  DELETE_CARD = "DELETE_CARD",
  UPDATE_CARD = "UPDATE_CARD",

  // Mutations
  SET_LOADING_CARD = "SET_LOADING_CARD",

  INSERT_CARD = "INSERT_CARD",
  INSERT_CARDS = "INSERT_CARDS",
  REMOVE_CARD = "REMOVE_CARD",

  SET_CARD_PAGES = "SET_CARD_PAGES",

  SET_ADD_CARD_LOADING = "SET_ADD_CARD_LOADING",
  SET_ADD_CARD_DIALOG = "SET_ADD_CARD_DIALOG",
  SET_ADD_CARD_ERROR = "SET_ADD_CARD_ERROR",

  SET_UPDATE_CARD_LOADING = "SET_UPDATE_CARD_LOADING",
  SET_UPDATE_CARD_DIALOG = "SET_UPDATE_CARD_DIALOG",
  SET_UPDATE_CARD_ERROR = "SET_UPDATE_CARD_ERROR",

  SET_DELETE_CARD_LOADING = "SET_DELETE_CARD_LOADING",
  SET_DELETE_CARD_DIALOG = "SET_DELETE_CARD_DIALOG",
  SET_DELETE_CARD_ERROR = "SET_DELETE_CARD_ERROR",
}

export function pbCategoryToString(
  category: 0 | 1 | 2 | 3 | 4 | 5 | 6
): string {
  switch (category) {
    case 1:
      return "HERBICIDES";

    case 2:
      return "INSECTICIDES";

    case 3:
      return "ORGANICS";

    case 4:
      return "FUNGICIDES";

    case 5:
      return "PUMPS";

    case 6:
      return "OTHERS";
    default:
      return "UNSPECIFIED";
  }
}
