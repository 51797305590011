import { pbGenderToString } from "@/store/modules/dealer/dealer.types";
import { Traceability } from "@/store/modules/traceability/traceability.types";
import { pbCoordinate, pbLocation } from "@/types/types";
import { grpc } from "@improbable-eng/grpc-web";
import { GetTraceabilitiesRequest } from "acard-protos/js/v1/traceability/rpc_get_traceabilities_pb";
import { TraceabilityRecord } from "acard-protos/js/v1/traceability/traceability_pb";
import { TraceabilityServiceClient } from "acard-protos/js/v1/traceability/traceability_service_pb_service";
import { GetTraceabilityRequest } from "acard-protos/js/v1/traceability/rpc_get_traceability_pb";
import { ExportTraceabilitiesRequest } from "acard-protos/js/v1/traceability/rpc_export_traceabilities_pb";
import { downloadUint8ArrayAsFile } from "@/mixins/utils";
import { nowAsEpoch } from "@/mixins/time";

const traceabilitiesClient = new TraceabilityServiceClient(
  process.env.VUE_APP_API_URL
);

export function getTraceabilities(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<{ traceabilities: Traceability[]; pages: number }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const traceabilitiesRequest = new GetTraceabilitiesRequest();
    traceabilitiesRequest.setPage(page);
    traceabilitiesRequest.setLimit(offset);
    traceabilitiesRequest.setQuery(query);

    traceabilitiesClient.getTraceabilities(
      traceabilitiesRequest,
      metaData,
      (error, response) => {
        if (error != null) {
          reject(error?.message ?? "Error loading traceabilities");
        } else {
          if (response) {
            resolve({
              traceabilities: pbTraceabilityRecordsToTraceability(
                response.getTraceabilitiesList()
              ),
              pages: response.getPages(),
            });
          } else {
            reject("no");
          }
        }
      }
    );
  });
}

export function getTraceability(id: string): Promise<Traceability> {
  return new Promise((resolve, reject) => {
    // Auth

    const traceabilityRequest = new GetTraceabilityRequest();
    traceabilityRequest.setId(id);

    traceabilitiesClient.getTraceability(
      traceabilityRequest,
      (error, response) => {
        if (error != null) {
          reject(error?.message ?? "Error loading traceabilities");
        } else {
          if (response) {
            resolve(pbAgroTraceabilityToPb(response));
          } else {
            reject("no");
          }
        }
      }
    );
  });
}

export function exportTraceabilities(authKey: string): Promise<void> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const exportRequest = new ExportTraceabilitiesRequest();

    traceabilitiesClient.exportTraceabilities(
      exportRequest,
      metaData,
      (error, response) => {
        if (error != null) {
          reject(error?.message ?? "Error exporting traceabilities");
        } else {
          if (response) {
            // Download as zip file
            const zipFileName = `acard-qrcodes-${nowAsEpoch()}.zip`;
            downloadUint8ArrayAsFile(
              response.getZippedTraceabilities(),
              zipFileName
            );
            resolve();
          } else {
            reject("no traceabilities");
          }
        }
      }
    );
  });
}

/*
export function addTraceabilities(
  authKey: string,
  traceabilities: AddTraceability[]
): Promise<{ count: number; errors: string[] }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const traceabilitiesRequest = new AddTraceabilitiesRequest();
    traceabilitiesRequest.setTraceabilitiesList(traceabilities.map(addTraceabilityToPb));

    traceabilitiesClient.addTraceabilities(
      traceabilitiesRequest,
      metaData,
      (error, response) => {
        if (error != null) {
          reject(error?.message ?? "Error uploading traceabilities");
        } else {
          if (response) {
            resolve({
              count: response.getInserted(),
              errors: response.getErrorsList(),
            });
          } else {
            reject("no");
          }
        }
      }
    );
  });
}
*/
export async function addTraceability(authKey: string): Promise<void> {
  // TODO - Add traceability
}

export default { getTraceabilities, getTraceability, exportTraceabilities };

function pbTraceabilityRecordsToTraceability(
  pbAgroTraceabilities: TraceabilityRecord[]
): Traceability[] {
  return pbAgroTraceabilities.map(pbAgroTraceabilityToPb);
}

function pbAgroTraceabilityToPb(
  traceability: TraceabilityRecord
): Traceability {
  return {
    id: traceability.getId(),
    farmer_code: traceability.getFarmerCode(),
    date_of_registration:
      traceability.getDateOfRegistration()?.toDate() ?? new Date(),
    date_of_first_inspection:
      traceability.getDateOfFirstInspection()?.toDate() ?? new Date(),
    date_of_last_inspection:
      traceability.getDateOfLastInspection()?.toDate() ?? new Date(),
    is_organic_compliant: traceability.getIsOrganicCompliant(),
    altitude: traceability.getAltitude(),
    gps_coordinates: pbCoordinate(traceability.getGpsCoordinates()),
    acreage_under_construction: traceability.getAcreageUnderConstruction(),
    acreage_other_crops: traceability.getAcreageOtherCrops(),
    number_coffee_plots: traceability.getNumberCofeePlots(),
    acreage_for_coffee: traceability.getAcreageForCoffee(),
    total_land_acreage: traceability.getTotalLandAcreage(),
    total_coffee_trees: traceability.getTotalCoffeeTrees(),
    total_productive_coffee_trees: traceability.getTotalProductiveCoffeeTrees(),
    parch_per_tree_per_year: traceability.getParchPerTreePerYear(),
    total_yield: traceability.getTotalYield(),
    location: pbLocation(traceability.getLocation()) || {
      village: "",
      parish: "",
      sub_county: "",
      county: "",
      district: "",
    },
    full_name: traceability.getFullName(),
    gender: pbGenderToString(traceability.getGender()) ?? "",

    created_at: traceability.getCreatedAt()?.toDate() ?? new Date(),
    updated_at: traceability.getCreatedAt()?.toDate(),
  };
}
