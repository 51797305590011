import { RootState } from "./root.state";
import { RootTypes } from "./root.types";

export default {
  /**
   * Opens a general Snackbar basing on the passed configurations.
   *
   * @see {@link [types.openSnackbar]}
   * @param state
   * @param {string} color : The Snackbar's color.
   * @param {string} message : The Snackbar's message.
   */
  [RootTypes.openSnackbar](
    state: RootState,
    {
      color = "error",
      message,
      title = "Error",
    }: {
      color?: string;
      message: string;
      title?: string;
    }
  ) {
    state[RootTypes.snackbar].color = color;
    state[RootTypes.snackbar].message = message;
    state[RootTypes.snackbar].title = title;
    state[RootTypes.snackbar].model = true;

    setTimeout(() => {
      state[RootTypes.snackbar].model = false;
    }, 3500);
  },
  /**
   * Closes a general snackbar.
   *
   * @see {@link [types.closeSnackbar]}
   * @param state
   */
  [RootTypes.closeSnackbar](state: RootState) {
    state[RootTypes.snackbar].model = false;
    state[RootTypes.snackbar].color = "error";
    state[RootTypes.snackbar].message = "";
  },
  /**
   * Changes the value of "loading", to the value passed as payload
   *
   * @see {@link [types.setLoading]}
   * @param state
   * @param {boolean} isLoading : Whether or not the progress is loading
   */
  [RootTypes.setLoading](state: RootState, isLoading: boolean) {
    state[RootTypes.loading] = isLoading;
  },
};
