import { DefaultState } from "@/types/types";
import { AddInputRequest } from "acard-protos/js/v1/input/rpc_add_input_pb";

export type Input = {
  id: number;
  name: string;
  category: string;
  package: string;
  company: string;
  unit: string;
  created_at: Date;
  updated_at?: Date;
};

export type AddInput = {
  name: string;
  package: string;
  category: string;
  company: string;
  unit: string;
};

export type InputsState = {
  inputs: Input[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export enum InputTypes {
  // Actions
  LOAD_INPUTS = "LOAD_INPUTS",
  UPLOAD_INPUTS = "UPLOAD_INPUTS",
  ADD_INPUT = "ADD_INPUT",
  DELETE_INPUT = "DELETE_INPUT",
  UPDATE_INPUT = "UPDATE_INPUT",

  // Mutations
  SET_LOADING_INPUT = "SET_LOADING_INPUT",
  SET_UPLOADING_INPUT = "SET_UPLOADING_INPUT",

  INSERT_INPUT = "INSERT_INPUT",
  INSERT_INPUTS = "INSERT_INPUTS",
  REMOVE_INPUT = "REMOVE_INPUT",

  SET_INPUT_PAGES = "SET_INPUT_PAGES",

  SET_ADD_INPUT_LOADING = "SET_ADD_INPUT_LOADING",
  SET_ADD_INPUT_DIALOG = "SET_ADD_INPUT_DIALOG",
  SET_ADD_INPUT_ERROR = "SET_ADD_INPUT_ERROR",

  SET_UPDATE_INPUT_LOADING = "SET_UPDATE_INPUT_LOADING",
  SET_UPDATE_INPUT_DIALOG = "SET_UPDATE_INPUT_DIALOG",
  SET_UPDATE_INPUT_ERROR = "SET_UPDATE_INPUT_ERROR",

  SET_DELETE_INPUT_LOADING = "SET_DELETE_INPUT_LOADING",
  SET_DELETE_INPUT_DIALOG = "SET_DELETE_INPUT_DIALOG",
  SET_DELETE_INPUT_ERROR = "SET_DELETE_INPUT_ERROR",

  SET_UPLOAD_INPUT_LOADING = "SET_UPLOAD_INPUT_LOADING",
  SET_UPLOAD_INPUT_DIALOG = "SET_UPLOAD_INPUT_DIALOG",
  SET_UPLOAD_INPUT_ERROR = "SET_UPLOAD_INPUT_ERROR",
}

export function pbCategoryToString(
  category: 0 | 1 | 2 | 3 | 4 | 5 | 6
): string {
  switch (category) {
    case 1:
      return "HERBICIDES";

    case 2:
      return "INSECTICIDES";

    case 3:
      return "ORGANICS";

    case 4:
      return "FUNGICIDES";

    case 5:
      return "PUMPS";

    case 6:
      return "OTHERS";
    default:
      return "UNSPECIFIED";
  }
}

export function stringCategoryToPb(
  category: string
): 0 | 1 | 2 | 3 | 4 | 5 | 6 {
  switch (category) {
    case "HERBICIDES":
      return 1;

    case "INSECTICIDES":
      return 2;

    case "ORGANICS":
      return 3;

    case "FUNGICIDES":
      return 4;

    case "PUMPS":
      return 5;

    default:
      return 6;
  }
}

export function addInputToPb(addInput: AddInput): AddInputRequest {
  const req = new AddInputRequest();

  req.setName(addInput.name);
  req.setCategory(stringCategoryToPb(addInput.category));
  req.setUnits(addInput.unit);
  req.setCompany(addInput.company);
  req.setPackage(addInput.package);

  return req;
}
