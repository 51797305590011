import {
  AgroEnterprise,
  EnterpriseVariety,
  EnterpriseWithVarieties,
} from "@/store/modules/enterprise/enterprise.types";
import { grpc } from "@improbable-eng/grpc-web";
import { EnterpriseResponse } from "acard-protos/js/v1/enterprise/enterprise_pb";
import { EnterpriseServiceClient } from "acard-protos/js/v1/enterprise/enterprise_service_pb_service";
import { GetEnterprisesRequest } from "acard-protos/js/v1/enterprise/rpc_get_enterprises_pb";

// type EnterpriseResponse = {
//   enterprises: Array<AgroEnterprise>;
// };

const enterpriseService = new EnterpriseServiceClient(
  process.env.VUE_APP_API_URL
);

export async function getEnterprises(authKey: string): Promise<{
  enterprises: Array<AgroEnterprise>;
}> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const req = new GetEnterprisesRequest();

    enterpriseService.getEnterprises(req, metaData, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        if (response) {
          const enterprises: AgroEnterprise[] = response
            .getEnterprisesList()
            .map(function (entr) {
              const enterprise: AgroEnterprise = {
                name: entr.getName(),
                id: entr.getId(),
                created_at: entr.getCreatedAt()?.toDate() ?? new Date(),
                updated_at: entr.getUpdatedAt()?.toDate(),
              };
              return enterprise;
            });

          resolve({ enterprises });
        } else {
          reject("No enterprises");
        }
      }
    });
  });
}

export function searchEnterprises(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<{ enterprises: EnterpriseWithVarieties[]; pages: number }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const enterprisesRequest = new GetEnterprisesRequest();
    enterprisesRequest.setPage(page);
    enterprisesRequest.setLimit(offset);
    enterprisesRequest.setQuery(query);

    enterpriseService.getEnterprises(
      enterprisesRequest,
      metaData,
      (error, response) => {
        if (error != null) {
          reject(error?.message ?? "Error loading enterprises");
        } else {
          if (response) {
            resolve({
              enterprises: pbAgroEnterprisesToEnterprises(
                response.getEnterprisesList()
              ),
              pages: response.getPages(),
            });
          } else {
            reject("no");
          }
        }
      }
    );
  });
}

export const addEnterprise = async (
  enterprise: AgroEnterprise
): Promise<void> => {
  // TODO - add enterprise
};

export const updateEnterprise = async (
  farmer: AgroEnterprise
): Promise<void> => {
  // TODO - Send PUT Request to api
};

export const deleteEnterprise = async (
  farmer: AgroEnterprise
): Promise<void> => {
  // TODO - Send DELETE Request to api
};

export default {
  getEnterprises,
  searchEnterprises,
  addEnterprise,
  updateEnterprise,
  deleteEnterprise,
};

function pbAgroEnterprisesToEnterprises(
  pbAgroEnterprises: EnterpriseResponse[]
): EnterpriseWithVarieties[] {
  return pbAgroEnterprises.map(pbAgroEnterpriseToPb);
}

function pbAgroEnterpriseToPb(
  input: EnterpriseResponse
): EnterpriseWithVarieties {
  return {
    name: input.getName(),
    id: input.getId(),
    varieties: input.getVarietiesList().map(function (v) {
      return <EnterpriseVariety>{
        name: v.getName(),
        id: v.getId(),
        created_at: v.getCreatedAt()?.toDate() ?? new Date(),
        updated_at: v.getUpdatedAt()?.toDate(),
      };
    }),
    created_at: input.getCreatedAt()?.toDate() ?? new Date(),
    updated_at: input.getUpdatedAt()?.toDate(),
  };
}
