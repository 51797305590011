import {
  AddInput,
  addInputToPb,
  Input,
  pbCategoryToString,
} from "@/store/modules/input/input.types";
import { grpc } from "@improbable-eng/grpc-web";
import { AgroInput, AgroInputDetails } from "acard-protos/js/v1/input/input_pb";
import { InputServiceClient } from "acard-protos/js/v1/input/input_service_pb_service";
import { AddInputsRequest } from "acard-protos/js/v1/input/rpc_add_input_pb";
import { GetInputsRequest } from "acard-protos/js/v1/input/rpc_get_inputs_pb";

const inputsClient = new InputServiceClient(process.env.VUE_APP_API_URL);

export function getInputs(
  authKey: string,
  page = 1,
  offset = 10,
  query = ""
): Promise<{ inputs: AddInput[]; pages: number }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const inputsRequest = new GetInputsRequest();
    inputsRequest.setPage(page);
    inputsRequest.setLimit(offset);
    inputsRequest.setQuery(query);

    inputsClient.getInputs(inputsRequest, metaData, (error, response) => {
      if (error != null) {
        reject(error?.message ?? "Error loading inputs");
      } else {
        if (response) {
          resolve({
            inputs: pbAgroInputDetailsToInputs(response.getInputsList()),
            pages: response.getPages(),
          });
        } else {
          reject("no");
        }
      }
    });
  });
}

export function addInputs(
  authKey: string,
  inputs: AddInput[]
): Promise<{ count: number; errors: string[] }> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const inputsRequest = new AddInputsRequest();
    inputsRequest.setInputsList(inputs.map(addInputToPb));

    inputsClient.addInputs(inputsRequest, metaData, (error, response) => {
      if (error != null) {
        reject(error?.message ?? "Error uploading inputs");
      } else {
        if (response) {
          resolve({
            count: response.getInserted(),
            errors: response.getErrorsList(),
          });
        } else {
          reject("no");
        }
      }
    });
  });
}

export async function addInput(authKey: string): Promise<void> {
  // TODO - Add input
}

export default { getInputs, addInputs };

function pbAgroInputsToInputs(pbAgroInputs: AgroInput[]): Input[] {
  return pbAgroInputs.map(pbAgroInputToPb);
}

function pbAgroInputToPb(input: AgroInput): Input {
  return {
    name: input.getName(),
    id: input.getId(),
    unit: input.getUnit(),
    category: pbCategoryToString(input.getCategory()),
    package: input.getPackage(),
    company: input.getCompany(),
    created_at: input.getCreatedAt()?.toDate() ?? new Date(),
    updated_at: input.getCreatedAt()?.toDate(),
  };
}

function pbAgroInputDetailsToInputs(
  pbAgroInputs: AgroInputDetails[]
): AddInput[] {
  return pbAgroInputs.map(pbAgroInputDetailToPb);
}

function pbAgroInputDetailToPb(input: AgroInputDetails): AddInput {
  return {
    name: input.getName(),
    unit: input
      .getUnitsList()
      .map((x) => x.getUnit())
      .join(", "),
    category: pbCategoryToString(input.getCategory()),
    package: input.getPackage(),
    company: input.getCompany(),
  };
}
