import { DefaultState, Pagination } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { FarmerCard, CardTypes } from "./card.types";
import cardService from "@/services/card.service";
import { RootTypes } from "../root.types";

@Module({ namespaced: true })
class CardModule extends VuexModule {
  public cards: FarmerCard[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public add: DefaultState = this.defaultState;

  public delete: DefaultState = this.defaultState;

  public update: DefaultState = this.defaultState;

  public loadingCard = false;

  // Set card pages count
  @Mutation
  public [CardTypes.SET_CARD_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Card
  @Mutation
  public [CardTypes.SET_LOADING_CARD](isLoadingCard: boolean): void {
    this.loadingCard = isLoadingCard;
  }

  // Add Card
  @Mutation
  public [CardTypes.SET_ADD_CARD_DIALOG](isAddingCardDialog: boolean): void {
    this.add.dialog = isAddingCardDialog;
  }

  @Mutation
  public [CardTypes.INSERT_CARDS](cards: FarmerCard[]): void {
    // this.cards.splice(0, this.cards.length);
    // this.cards.push(...cards);
    this.cards.splice(0, this.cards.length);
    this.cards.push(...cards);
  }

  @Mutation
  public [CardTypes.SET_ADD_CARD_LOADING](
    isAddingCardLoading: boolean
  ): void {
    this.add.loading = isAddingCardLoading;
  }

  @Mutation
  public [CardTypes.SET_ADD_CARD_ERROR](addError: string): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Card
  @Mutation
  public [CardTypes.SET_DELETE_CARD_DIALOG](
    isDeletingCardDialog: boolean
  ): void {
    this.delete.dialog = isDeletingCardDialog;
  }

  @Mutation
  public [CardTypes.SET_DELETE_CARD_LOADING](
    isDeletingCardLoading: boolean
  ): void {
    this.delete.loading = isDeletingCardLoading;
  }

  @Mutation
  public [CardTypes.SET_DELETE_CARD_ERROR](deleteError: string): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Update Card
  @Mutation
  public [CardTypes.SET_UPDATE_CARD_DIALOG](
    isUpdatingCardDialog: boolean
  ): void {
    this.update.dialog = isUpdatingCardDialog;
  }

  @Mutation
  public [CardTypes.SET_UPDATE_CARD_LOADING](
    isUpdatingCardLoading: boolean
  ): void {
    this.update.loading = isUpdatingCardLoading;
  }

  @Mutation
  public [CardTypes.SET_UPDATE_CARD_ERROR](updateError: string): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Card
  @Mutation
  public [CardTypes.INSERT_CARD](card: FarmerCard): void {
    const index = this.cards.map((x) => x.id).indexOf(card.id);

    if (index > -1) {
      this.cards.splice(index, 1, card);
    } else {
      this.cards.splice(0, 0, card);
    }
  }

  // Remove Card
  @Mutation
  public [CardTypes.REMOVE_CARD](card: FarmerCard): void {
    const index = this.cards.map((x) => x.id).indexOf(card.id);

    if (index > -1) {
      this.cards.splice(index);
    }
  }

  // Load Cards
  @Action
  public async [CardTypes.LOAD_CARDS]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    this.context.commit(CardTypes.SET_LOADING_CARD, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const { cards, pages } = await cardService.getCards(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(CardTypes.INSERT_CARDS, cards);
      this.context.commit(CardTypes.SET_CARD_PAGES, pages);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading cards" },
        { root: true }
      );
    } finally {
      this.context.commit(CardTypes.SET_LOADING_CARD, false);
    }
  }
  // Add Card
  @Action
  public async [CardTypes.ADD_CARD](card: FarmerCard): Promise<void> {
    this.context.commit(CardTypes.SET_ADD_CARD_LOADING, true);

    try {
      // const fm = await cardService.addCard(card);
      // this.context.commit(CardTypes.INSERT_CARD, fm);
      // this.context.commit(CardTypes.SET_ADD_CARD_DIALOG, false);
      // this.context.commit(
      //   RootTypes.openSnackbar,
      //   { message: "Card Saved", color: "success" },
      //   { root: true }
      // );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add card" },
        { root: true }
      );
    } finally {
      this.context.commit(CardTypes.SET_ADD_CARD_LOADING, false);
    }
  }

  // Delete Card
  @Action
  public async [CardTypes.DELETE_CARD](card: FarmerCard): Promise<void> {
    this.context.commit(CardTypes.SET_DELETE_CARD_LOADING, true);

    try {
      // const fm = await cardService.deleteCard(card);
      // this.context.commit(CardTypes.REMOVE_CARD, fm);
      // this.context.commit(CardTypes.SET_DELETE_CARD_DIALOG, false);
    } catch (e) {
      this.context.commit(
        CardTypes.SET_DELETE_CARD_ERROR,
        "Failed to delete card"
      );
    } finally {
      this.context.commit(CardTypes.SET_DELETE_CARD_LOADING, false);
    }
  }

  // Update Card
  @Action
  public async [CardTypes.UPDATE_CARD](card: FarmerCard): Promise<void> {
    this.context.commit(CardTypes.SET_UPDATE_CARD_LOADING, true);

    try {
      // const fm = await cardService.updateCard(card);
      // this.context.commit(CardTypes.ADD_CARD, fm);
      // this.context.commit(CardTypes.SET_UPDATE_CARD_DIALOG, false);
    } catch (e) {
      this.context.commit(
        CardTypes.SET_UPDATE_CARD_ERROR,
        "Failed to update card"
      );
    } finally {
      this.context.commit(CardTypes.SET_UPDATE_CARD_LOADING, false);
    }
  }
}

export default CardModule;
