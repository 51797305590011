import { DefaultState, JsShortUserProfile } from "@/types/types";

export type Produce = {
  id: number;
  user?: JsShortUserProfile;
  agent?: JsShortUserProfile;
  crop: string;
  quantity: number;
  unit: string;
  state?: string;
  price: number;
  moisture?: number;
  variety?: string;
  produce_type?: string;
  created_at: Date;
  updated_at?: number;
};

export type ProducesState = {
  produces: Produce[];
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export enum ProduceTypes {
  // Actions
  LOAD_PRODUCES = "LOAD_PRODUCES",
  ADD_PRODUCE = "ADD_PRODUCE",
  DELETE_PRODUCE = "DELETE_PRODUCE",
  UPDATE_PRODUCE = "UPDATE_PRODUCE",

  // Mutations
  SET_LOADING_PRODUCE = "SET_LOADING_PRODUCE",

  INSERT_PRODUCE = "INSERT_PRODUCE",
  INSERT_PRODUCES = "INSERT_PRODUCES",
  REMOVE_PRODUCE = "REMOVE_PRODUCE",

  SET_PRODUCE_PAGES = "SET_PRODUCE_PAGES",

  SET_ADD_PRODUCE_LOADING = "SET_ADD_PRODUCE_LOADING",
  SET_ADD_PRODUCE_DIALOG = "SET_ADD_PRODUCE_DIALOG",
  SET_ADD_PRODUCE_ERROR = "SET_ADD_PRODUCE_ERROR",

  SET_UPDATE_PRODUCE_LOADING = "SET_UPDATE_PRODUCE_LOADING",
  SET_UPDATE_PRODUCE_DIALOG = "SET_UPDATE_PRODUCE_DIALOG",
  SET_UPDATE_PRODUCE_ERROR = "SET_UPDATE_PRODUCE_ERROR",

  SET_DELETE_PRODUCE_LOADING = "SET_DELETE_PRODUCE_LOADING",
  SET_DELETE_PRODUCE_DIALOG = "SET_DELETE_PRODUCE_DIALOG",
  SET_DELETE_PRODUCE_ERROR = "SET_DELETE_PRODUCE_ERROR",
}

export function pbCategoryToString(
  category: 0 | 1 | 2 | 3 | 4 | 5 | 6
): string {
  switch (category) {
    case 1:
      return "HERBICIDES";

    case 2:
      return "INSECTICIDES";

    case 3:
      return "ORGANICS";

    case 4:
      return "FUNGICIDES";

    case 5:
      return "PUMPS";

    case 6:
      return "OTHERS";
    default:
      return "UNSPECIFIED";
  }
}
