import {
  AuthVerifyPayload,
  RefreshTokenPayload,
  SignByPhonePayload,
  TokenPayload,
} from "@/store/modules/auth/auth.types";

import { AuthServiceClient } from "acard-protos/js/v1/auth/auth_service_pb_service";
import { LoginByPhoneRequest } from "acard-protos/js/v1/auth/rpc_login_by_phone_pb";
import { RefreshTokenRequest } from "acard-protos/js/v1/auth/rpc_refresh_token_pb";
import {
  AuthPayload,
  VerifyAuthRequest,
} from "acard-protos/js/v1/auth/rpc_verify_secret_pb";
import { AuthSecret } from "acard-protos/js/v1/auth/secret_pb";

const authClient = new AuthServiceClient(process.env.VUE_APP_API_URL);

export function signInWithPhone({
  phone,
}: SignByPhonePayload): Promise<AuthSecret | null> {
  const loginByPhoneRequest = new LoginByPhoneRequest();
  loginByPhoneRequest.setPhone(phone);

  return new Promise((resolve, reject) => {
    authClient.loginByPhone(loginByPhoneRequest, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
}

export function verifyCode({
  code,
  secret,
}: AuthVerifyPayload): Promise<AuthPayload | null> {
  const verifyAuthRequest = new VerifyAuthRequest();
  verifyAuthRequest.setCode(code);
  verifyAuthRequest.setSecret(secret);

  return new Promise((resolve, reject) => {
    authClient.verifyAuthSecret(verifyAuthRequest, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        resolve(response);
      }
    });
  });
}

export function refreshAuthToken({
  refresh_token,
}: RefreshTokenPayload): Promise<TokenPayload> {
  const refreshTokenRequest = new RefreshTokenRequest();
  refreshTokenRequest.setRefreshToken(refresh_token);

  return new Promise((resolve, reject) => {
    authClient.refreshAuthToken(refreshTokenRequest, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        if (response) {
          const payload: TokenPayload = {
            token: response.getAccessToken(),
            expiry: response.getAccessTokenExpiresAt()?.toDate() ?? new Date(),
          };

          resolve(payload);
        } else {
          reject("token not created");
        }
      }
    });
  });
}

export default {
  signInWithPhone,
  verifyCode,
  refreshAuthToken,
};
