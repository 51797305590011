import statsService from "@/services/stats.service";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { RootTypes } from "../root.types";
import {
  DashboardStatsState,
  InputCategoryStats,
  OrderStats,
  OrderStatsPayload,
  StatsState,
  StatTypes,
} from "./stats.types";

@Module({ namespaced: true })
class Stats extends VuexModule {
  public loading = false;

  public stats: StatsState = {
    farmers: 0,
    dealers: 0,
    sales: 0,
    orders: 0,
  };

  // Order Stats
  public dashboardStats: DashboardStatsState = {
    order: {
      stats: {
        sales: [],
        orders: [],
        labels: [],
        colors: [],
      },
      loading: false,
    },
    inputs: {
      stats: {
        labels: [],
        data: [],
      },
      loading: false,
    },
  };

  // Order
  @Mutation
  public [StatTypes.SET_ORDER_STATS_LOADING](isLoading: boolean): void {
    this.dashboardStats.order.loading = isLoading;
  }

  @Mutation
  public [StatTypes.SET_ORDER_STATS_ERROR](error: string): void {
    this.dashboardStats.order.error = error;
  }

  @Mutation
  public [StatTypes.SET_ORDER_STATS](stats: OrderStats): void {
    this.dashboardStats.order.stats = stats;
  }

  // Inputs
  @Mutation
  public [StatTypes.SET_INPUT_CATEGORIES_STATS_LOADING](
    isLoading: boolean
  ): void {
    this.dashboardStats.inputs.loading = isLoading;
  }

  @Mutation
  public [StatTypes.SET_INPUT_CATEGORIES_STATS_ERROR](error: string): void {
    this.dashboardStats.inputs.error = error;
  }

  @Mutation
  public [StatTypes.SET_INPUT_CATEGORIES_STATS](
    stats: InputCategoryStats
  ): void {
    this.dashboardStats.inputs.stats = stats;
  }

  @Mutation
  public [StatTypes.SET_STATS_LOADING](isLoading: boolean): void {
    this.loading = isLoading;
  }

  @Mutation
  public [StatTypes.SET_STATS_DEALERS](dealers: number): void {
    this.stats.dealers = dealers;
  }

  @Mutation
  public [StatTypes.SET_STATS_FARMERS](farmers: number): void {
    this.stats.farmers = farmers;
  }

  @Mutation
  public [StatTypes.SET_STATS_ORDERS](orders: number): void {
    this.stats.orders = orders;
  }

  @Mutation
  public [StatTypes.SET_STATS_SALES](sales: number): void {
    this.stats.sales = sales;
  }

  @Action({ rawError: true })
  public async [StatTypes.GET_STATS](): Promise<void> {
    this.context.commit(StatTypes.SET_STATS_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const result = await statsService.getPortalStats(authHeader);

      if (result) {
        this.context.commit(StatTypes.SET_STATS_DEALERS, result.getDealers());
        this.context.commit(StatTypes.SET_STATS_FARMERS, result.getFarmers());
        this.context.commit(StatTypes.SET_STATS_SALES, result.getSales());
        this.context.commit(StatTypes.SET_STATS_ORDERS, result.getOrders());
      }
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading stats" },
        { root: true }
      );
    } finally {
      this.context.commit(StatTypes.SET_STATS_LOADING, false);
    }
  }

  @Action({ rawError: true })
  public async [StatTypes.GET_ORDER_STATS](
    payload: OrderStatsPayload
  ): Promise<void> {
    this.context.commit(StatTypes.SET_ORDER_STATS_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const result = await statsService.getOrderStats(authHeader, payload);

      if (result) {
        this.context.commit(StatTypes.SET_ORDER_STATS, result);
      }
    } catch (e) {
      console.log(e);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading order stats" },
        { root: true }
      );
    } finally {
      this.context.commit(StatTypes.SET_ORDER_STATS_LOADING, false);
    }
  }

  @Action({ rawError: true })
  public async [StatTypes.GET_INPUT_CATEGORIES_STATS](): Promise<void> {
    this.context.commit(StatTypes.SET_INPUT_CATEGORIES_STATS_LOADING, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const result = await statsService.getInputCategoryStats(authHeader);

      if (result) {
        this.context.commit(StatTypes.SET_INPUT_CATEGORIES_STATS, result);
      }
    } catch (e) {
      console.log(e);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading input stats" },
        { root: true }
      );
    } finally {
      this.context.commit(StatTypes.SET_INPUT_CATEGORIES_STATS_LOADING, false);
    }
  }
}
export default Stats;
