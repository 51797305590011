import { RootTypes } from "./root.types";
export type SnackbarState = {
  model: boolean;
  color: string;
  message: string;
  title: string;
};

export type RootState = {
  [RootTypes.snackbar]: SnackbarState;
  [RootTypes.loading]: boolean;
};

export const state: RootState = {
  // Snackbar
  [RootTypes.snackbar]: {
    model: false,
    color: "error",
    message: "",
    title: "",
  },

  // Loading
  [RootTypes.loading]: false,
};
