import store from "@/store";
import { SignInTypes } from "@/store/modules/auth/auth.types";
import { RootTypes } from "@/store/modules/root.types";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "../views/auth/Login.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/main/HomeView.vue"),
    redirect: "/home/dashboard",
    children: [
      {
        path: "/home/dashboard",
        name: "dashboard",
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/main/Dashboard.vue"
          ),
      },
      {
        path: "/home/farmers",
        name: "farmers",
        component: () =>
          import(
            /* webpackChunkName: "farmers" */ "../views/main/FarmersView.vue"
          ),
      },
      {
        path: "/home/agro-dealers",
        name: "agro-dealers",
        component: () =>
          import(
            /* webpackChunkName: "agro-dealers" */ "../views/main/AgroDealersView.vue"
          ),
      },
      {
        path: "/home/inputs",
        name: "inputs",
        component: () =>
          import(
            /* webpackChunkName: "inputs" */ "../views/main/InputsView.vue"
          ),
      },
      {
        path: "/home/coffee",
        name: "coffee",
        component: () =>
          import(
            /* webpackChunkName: "coffee" */ "../views/main/CoffeeView.vue"
          ),
      },
      {
        path: "/home/produce",
        name: "produce",
        component: () =>
          import(
            /* webpackChunkName: "produce" */ "../views/main/ProduceView.vue"
          ),
      },
      {
        path: "/home/enterprises",
        name: "enterprises",
        component: () =>
          import(
            /* webpackChunkName: "enterprises" */ "../views/main/EnterprisesView.vue"
          ),
      },
      {
        path: "/home/orders",
        name: "orders",
        component: () =>
          import(
            /* webpackChunkName: "orders" */ "../views/main/OrderView.vue"
          ),
      },
      {
        path: "/home/cards",
        name: "cards",
        component: () =>
          import(/* webpackChunkName: "cards" */ "../views/main/CardsView.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: "/coffee/:id",
    name: "coffee-farmer",
    meta: {
      requiresAuth: false,
    },
    component: () =>
        import(/* webpackChunkName: "home" */ "../views/main/CoffeeFarmerView.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.afterEach(() => {
  // Remove initial loading
  store.commit(RootTypes.setLoading, false);
});

router.beforeEach((to, from, next) => {
  // Start initial loading
  store.commit(RootTypes.setLoading, true);

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["Auth/isLoggedIn"]) {
      next();
    } else {
      if (store.getters["Auth/isRefreshable"]) {
        store.commit(`Auth/${SignInTypes.SET_LAST_PATH}`, to.path);
        store.dispatch(`Auth/${SignInTypes.REFRESH_TOKEN}`);
      }
      next("/login");
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters["Auth/isLoggedIn"]) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
