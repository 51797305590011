export enum RootTypes {
  // State
  snackBarMessage = "snackBarMessage",
  snackBarColor = "snackBarColor",
  snackbar = "snackbar",

  // Mutations
  setSnackBarMessage = "setSnackBarMessage",
  setSnackBarColor = "setSnackBarColor",
  setSnackBar = "setSnackBar",
  openSnackbar = "openSnackbar",
  closeSnackbar = "closeSnackbar",

  /** Loading **/
  // State
  loading = "loading",

  // Mutations
  setLoading = "setLoading",
}
