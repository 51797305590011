export type StatsState = {
  farmers: number;
  dealers: number;
  sales: number;
  orders: number;
};

export type OrderStatsPayload = {
  from?: Date;
  to?: Date;
};

export type DashboardStatsState = {
  order: {
    stats: OrderStats;
    loading: boolean;
    error?: string;
  };
  inputs: {
    stats: InputCategoryStats;
    loading: boolean;
    error?: string;
  };
};

export type OrderStats = {
  sales: number[];
  orders: number[];
  labels: string[];
  colors: string[];
};

export type InputCategoryStats = {
  data: number[];
  labels: string[];
};

export enum StatTypes {
  GET_STATS = "GET_STATS",
  SET_STATS_LOADING = "SET_STATS_LOADING",
  SET_STATS_ERROR = "SET_STATS_ERROR",
  SET_STATS_FARMERS = "SET_STATS_FARMERS",
  SET_STATS_DEALERS = "SET_STATS_DEALERS",
  SET_STATS_ORDERS = "SET_STATS_ORDERS",
  SET_STATS_SALES = "SET_STATS_SALES",

  // Order Stats
  GET_ORDER_STATS = "GET_ORDER_STATS",
  SET_ORDER_STATS_LOADING = "SET_ORDER_STATS_LOADING",
  SET_ORDER_STATS = "SET_ORDER_STATS",
  SET_ORDER_STATS_ERROR = "SET_ORDER_STATS_ERROR",

  // Order Stats
  GET_INPUT_CATEGORIES_STATS = "GET_INPUT_CATEGORIES_STATS",
  SET_INPUT_CATEGORIES_STATS_LOADING = "SET_INPUT_CATEGORIES_STATS_LOADING",
  SET_INPUT_CATEGORIES_STATS = "SET_INPUT_CATEGORIES_STATS",
  SET_INPUT_CATEGORIES_STATS_ERROR = "SET_INPUT_CATEGORIES_STATS_ERROR",
}
