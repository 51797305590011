import { DefaultState, Pagination } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Traceability, TraceabilityTypes } from "./traceability.types";
import traceabilityService from "@/services/traceability.service";
import { RootTypes } from "../root.types";

@Module({ namespaced: true })
class TraceabilityModule extends VuexModule {
  public traceabilities: Traceability[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public add: DefaultState = this.defaultState;

  public delete: DefaultState = this.defaultState;

  public update: DefaultState = this.defaultState;

  public upload: DefaultState = this.defaultState;

  public loadingTraceability = false;
  public [TraceabilityTypes.EXPORTING_TRACEABILITIES] = false;

  // Set traceability pages count
  @Mutation
  public [TraceabilityTypes.SET_TRACEABILITY_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Traceability Export
  @Mutation
  public [TraceabilityTypes.SET_EXPORT_TRACEABILITIES_LOADING](
    isExportingTraceability: boolean
  ): void {
    this[TraceabilityTypes.EXPORTING_TRACEABILITIES] = isExportingTraceability;
  }

  // Load Traceability
  @Mutation
  public [TraceabilityTypes.SET_LOADING_TRACEABILITY](
    isLoadingTraceability: boolean
  ): void {
    this.loadingTraceability = isLoadingTraceability;
  }

  // Add Traceability
  @Mutation
  public [TraceabilityTypes.SET_ADD_TRACEABILITY_DIALOG](
    isAddingTraceabilityDialog: boolean
  ): void {
    this.add.dialog = isAddingTraceabilityDialog;
  }

  @Mutation
  public [TraceabilityTypes.INSERT_TRACEABILITIES](
    traceabilities: Traceability[]
  ): void {
    this.traceabilities.splice(0, this.traceabilities.length);
    this.traceabilities.push(...traceabilities);
  }

  @Mutation
  public [TraceabilityTypes.SET_ADD_TRACEABILITY_LOADING](
    isAddingTraceabilityLoading: boolean
  ): void {
    this.add.loading = isAddingTraceabilityLoading;
  }

  @Mutation
  public [TraceabilityTypes.SET_ADD_TRACEABILITY_ERROR](
    addError: string
  ): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Traceability
  @Mutation
  public [TraceabilityTypes.SET_DELETE_TRACEABILITY_DIALOG](
    isDeletingTraceabilityDialog: boolean
  ): void {
    this.delete.dialog = isDeletingTraceabilityDialog;
  }

  @Mutation
  public [TraceabilityTypes.SET_DELETE_TRACEABILITY_LOADING](
    isDeletingTraceabilityLoading: boolean
  ): void {
    this.delete.loading = isDeletingTraceabilityLoading;
  }

  @Mutation
  public [TraceabilityTypes.SET_DELETE_TRACEABILITY_ERROR](
    deleteError: string
  ): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Upload Traceability
  @Mutation
  public [TraceabilityTypes.SET_UPLOAD_TRACEABILITY_DIALOG](
    isUploadingTraceabilityDialog: boolean
  ): void {
    this.upload.dialog = isUploadingTraceabilityDialog;
  }

  @Mutation
  public [TraceabilityTypes.SET_UPLOAD_TRACEABILITY_LOADING](
    isUploadingTraceabilityLoading: boolean
  ): void {
    this.upload.loading = isUploadingTraceabilityLoading;
  }

  @Mutation
  public [TraceabilityTypes.SET_UPLOAD_TRACEABILITY_ERROR](
    uploadError: string
  ): void {
    this.upload.error.errorMessage = uploadError;
    this.upload.error.error = true;
  }

  // Update Traceability
  @Mutation
  public [TraceabilityTypes.SET_UPDATE_TRACEABILITY_DIALOG](
    isUpdatingTraceabilityDialog: boolean
  ): void {
    this.update.dialog = isUpdatingTraceabilityDialog;
  }

  @Mutation
  public [TraceabilityTypes.SET_UPDATE_TRACEABILITY_LOADING](
    isUpdatingTraceabilityLoading: boolean
  ): void {
    this.update.loading = isUpdatingTraceabilityLoading;
  }

  @Mutation
  public [TraceabilityTypes.SET_UPDATE_TRACEABILITY_ERROR](
    updateError: string
  ): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Traceability
  @Mutation
  public [TraceabilityTypes.INSERT_TRACEABILITY](
    traceability: Traceability
  ): void {
    const index = this.traceabilities.map((x) => x.id).indexOf(traceability.id);

    if (index > -1) {
      this.traceabilities.splice(index, 1, traceability);
    } else {
      this.traceabilities.splice(0, 0, traceability);
    }
  }

  // Remove Traceability
  @Mutation
  public [TraceabilityTypes.REMOVE_TRACEABILITY](
    traceability: Traceability
  ): void {
    const index = this.traceabilities.map((x) => x.id).indexOf(traceability.id);

    if (index > -1) {
      this.traceabilities.splice(index);
    }
  }

  // Load Traceabilities
  @Action
  public async [TraceabilityTypes.LOAD_TRACEABILITIES]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    this.context.commit(TraceabilityTypes.SET_LOADING_TRACEABILITY, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const { traceabilities, pages } =
        await traceabilityService.getTraceabilities(
          authHeader,
          page,
          limit,
          query
        );
      this.context.commit(
        TraceabilityTypes.INSERT_TRACEABILITIES,
        traceabilities
      );
      this.context.commit(TraceabilityTypes.SET_TRACEABILITY_PAGES, pages);
    } catch (e) {
      console.log(e);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading traceabilities" },
        { root: true }
      );
    } finally {
      this.context.commit(TraceabilityTypes.SET_LOADING_TRACEABILITY, false);
    }
  }

  // Add Traceability
  @Action
  public async [TraceabilityTypes.ADD_TRACEABILITY](
    traceability: Traceability
  ): Promise<void> {
    this.context.commit(TraceabilityTypes.SET_ADD_TRACEABILITY_LOADING, true);

    try {
      // const fm = await traceabilityService.addTraceability(traceability);
      // this.context.commit(TraceabilityTypes.INSERT_TRACEABILITY, fm);
      // this.context.commit(TraceabilityTypes.SET_ADD_TRACEABILITY_DIALOG, false);
      // this.context.commit(
      //   RootTypes.openSnackbar,
      //   { message: "Traceability Saved", color: "success" },
      //   { root: true }
      // );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add traceability" },
        { root: true }
      );
    } finally {
      this.context.commit(
        TraceabilityTypes.SET_ADD_TRACEABILITY_LOADING,
        false
      );
    }
  }

  // Delete Traceability
  @Action
  public async [TraceabilityTypes.DELETE_TRACEABILITY](
    traceability: Traceability
  ): Promise<void> {
    this.context.commit(
      TraceabilityTypes.SET_DELETE_TRACEABILITY_LOADING,
      true
    );

    try {
      // const fm = await traceabilityService.deleteTraceability(traceability);
      // this.context.commit(TraceabilityTypes.REMOVE_TRACEABILITY, fm);
      // this.context.commit(TraceabilityTypes.SET_DELETE_TRACEABILITY_DIALOG, false);
    } catch (e) {
      this.context.commit(
        TraceabilityTypes.SET_DELETE_TRACEABILITY_ERROR,
        "Failed to delete traceability"
      );
    } finally {
      this.context.commit(
        TraceabilityTypes.SET_DELETE_TRACEABILITY_LOADING,
        false
      );
    }
  }

  // Update Traceability
  @Action
  public async [TraceabilityTypes.UPDATE_TRACEABILITY](
    traceability: Traceability
  ): Promise<void> {
    this.context.commit(
      TraceabilityTypes.SET_UPDATE_TRACEABILITY_LOADING,
      true
    );

    try {
      // const fm = await traceabilityService.updateTraceability(traceability);
      // this.context.commit(TraceabilityTypes.ADD_TRACEABILITY, fm);
      // this.context.commit(TraceabilityTypes.SET_UPDATE_TRACEABILITY_DIALOG, false);
    } catch (e) {
      this.context.commit(
        TraceabilityTypes.SET_UPDATE_TRACEABILITY_ERROR,
        "Failed to update traceability"
      );
    } finally {
      this.context.commit(
        TraceabilityTypes.SET_UPDATE_TRACEABILITY_LOADING,
        false
      );
    }
  }

  // Export Traceabilities
  @Action
  public async [TraceabilityTypes.EXPORT_TRACEABILITIES](): Promise<void> {
    this.context.commit(
      TraceabilityTypes.SET_EXPORT_TRACEABILITIES_LOADING,
      true
    );

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      await traceabilityService.exportTraceabilities(authHeader);
    } catch (e) {
      console.log(e);
      this.context.commit(
        TraceabilityTypes.SET_UPDATE_TRACEABILITY_ERROR,
        "Failed to export traceabilities"
      );
    } finally {
      this.context.commit(
        TraceabilityTypes.SET_EXPORT_TRACEABILITIES_LOADING,
        false
      );
    }
  }

  // Upload Traceability
  /*@Action
      public async [TraceabilityTypes.UPLOAD_TRACEABILITIES](
        traceabilities: AddTraceability[]
      ): Promise<void> {
        this.context.commit(
          TraceabilityTypes.SET_UPLOAD_TRACEABILITY_LOADING,
          true
        );
  
        try {
          const authHeader = this.context.rootGetters["Auth/authHeader"];
          const result = await traceabilityService.addTraceabilities(
            authHeader,
            traceabilities
          );
          // this.context.commit(TraceabilityTypes.ADD_TRACEABILITY, fm);
          this.context.commit(
            TraceabilityTypes.SET_UPLOAD_TRACEABILITY_DIALOG,
            false
          );
          this.context.commit(
            RootTypes.openSnackbar,
            { message: `Uploaded ${result.count} records`, color: "success" },
            { root: true }
          );
          // TODO: Handle errors
        } catch (e) {
          this.context.commit(
            RootTypes.openSnackbar,
            { message: "Failed to upload traceabilities" },
            { root: true }
          );
        } finally {
          this.context.commit(
            TraceabilityTypes.SET_UPLOAD_TRACEABILITY_LOADING,
            false
          );
        }
      }*/
}

export default TraceabilityModule;
