import { base64ToBlob, base64ToImage } from "@/mixins/files";
import { DefaultState, GpsCoordinate } from "@/types/types";
import { AddFarmerRequest } from "acard-protos/js/v1/farmer/rpc_add_farmer_pb";
import { CardRequest } from "acard-protos/js/v1/user/card_pb";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";
import { stringToPbCardType, stringToPbGender } from "../dealer/dealer.types";
import { AddCoffeeFarmerRequest } from "acard-protos/js/v1/farmer/rpc_add_coffee_farmer_pb";
import { Coordinate, Gender } from "acard-protos/js/meta/meta_pb";

export type Farmer = {
  id?: string;
  first_name: string;
  last_name: string;
  other_names?: string;
  dob: string;
  nfc_card_no: string;
  enterprise: Array<string>;
  phone_no: string;
  license_no: string;
  license_photo?: string;
  photo?: string;
  card: {
    type: "national-id" | "driving-permit";
    no?: string;
    front?: string;
    back?: string;
  };
  gender: "M" | "F";
  in_group?: boolean;
  cooperative?: string;
  group?: string;
  people_in_household?: number;
  land_size?: number;
  location: {
    district: string;
    county: string;
    sub_county: string;
    parish: string;
    village: string;
  };
};

export type AddFarmerPayload = {
  first_name: string;
  last_name: string;
  other_names?: string;
  dob: string;
  nfc_card_no: string;
  enterprise: Array<string>;
  phone_no: string;
  photo: string;
  card: {
    type: "national-id" | "driving-permit";
    no: string;
    front: string;
    back: string;
  };
  gender: "M" | "F";
  in_group?: boolean;
  cooperative?: string;
  group?: string;
  people_in_household?: number;
  land_size?: number;
  village: number;
  agent?: number;
};

export type FarmerUserProfile = {
  id: number;
  name: string;
  phone: string;
  photo: string;
  gender: string;
  village: string;
  district: string;
  createdAt: Date;
  updatedAt?: Date;
};

export type FarmersState = {
  farmers: Farmer[];
  loadingFarmer: boolean;
  add: DefaultState;
  delete: DefaultState;
  update: DefaultState;
};

export type AddCoffeeFarmer = {
  farmer_code: string;
  date_of_registration: Date;
  date_of_first_inspection: Date;
  date_of_last_inspection: Date;
  is_organic_compliant: boolean;
  altitude: number;
  gps_coordinates?: GpsCoordinate;
  acreage_under_construction?: number;
  acreage_other_crops?: number;
  number_coffee_plots: number;
  acreage_for_coffee: number;
  total_land_acreage: number;
  total_coffee_trees: number;
  total_productive_coffee_trees: number;
  parch_per_tree_per_year: number;
  total_yield: number;
  village: number;
  full_name: string;
  gender: string;
  agent?: number;
};

export enum FarmerTypes {
  // Actions
  LOAD_FARMERS = "LOAD_FARMERS",
  ADD_FARMER = "ADD_FARMER",
  DELETE_FARMER = "DELETE_FARMER",
  UPDATE_FARMER = "UPDATE_FARMER",
  UPLOAD_COFFEE_FARMERS = "UPLOAD_COFFEE_FARMERS",

  // Mutations
  INSERT_FARMER = "INSERT_FARMER",
  INSERT_FARMERS = "INSERT_FARMERS",
  REMOVE_FARMER = "REMOVE_FARMER",

  SET_LOADING_FARMER = "SET_LOADING_FARMER",

  SET_ADD_FARMER_LOADING = "SET_ADD_FARMER_LOADING",
  SET_ADD_FARMER_DIALOG = "SET_ADD_FARMER_DIALOG",
  SET_ADD_FARMER_DIALOG_REF = "SET_ADD_FARMER_DIALOG_REF",
  SET_ADD_FARMER_ERROR = "SET_ADD_FARMER_ERROR",

  SET_UPDATE_FARMER_LOADING = "SET_UPDATE_FARMER_LOADING",
  SET_UPDATE_FARMER_DIALOG = "SET_UPDATE_FARMER_DIALOG",
  SET_UPDATE_FARMER_ERROR = "SET_UPDATE_FARMER_ERROR",

  SET_DELETE_FARMER_LOADING = "SET_DELETE_FARMER_LOADING",
  SET_DELETE_FARMER_DIALOG = "SET_DELETE_FARMER_DIALOG",
  SET_DELETE_FARMER_ERROR = "SET_DELETE_FARMER_ERROR",

  SET_UPLOAD_COFFEE_FARMER_LOADING = "SET_UPLOAD_COFFEE_FARMER_LOADING",
  SET_UPLOAD_COFFEE_FARMER_DIALOG = "SET_UPLOAD_COFFEE_FARMER_DIALOG",
  SET_UPLOAD_COFFEE_FARMER_ERROR = "SET_UPLOAD_COFFEE_FARMER_ERROR",
}

export function farmerToFormData(farmer: Farmer): FormData {
  const data = new FormData();

  if (farmer.photo)
    data.append("photo", base64ToBlob(farmer.photo), "photo.png");
  if (farmer.card.front)
    data.append("front", base64ToBlob(farmer.card.front), "front.png");
  if (farmer.card.back)
    data.append("back", base64ToBlob(farmer.card.back), "back.png");

  const json = Object.assign({}, farmer);
  json.phone_no = `+256${json.phone_no}`;
  delete json.photo;
  delete json.card.front;
  delete json.card.back;
  delete json.license_photo;
  data.append("json", JSON.stringify(json));

  return data;
}

export function addFarmerPayloadToRequest(
  payload: AddFarmerPayload
): AddFarmerRequest {
  const request = new AddFarmerRequest();

  request.setFirstName(payload.first_name);
  request.setLastName(payload.last_name);
  request.setOtherNames(payload.other_names ?? "");
  request.setDateOfBirth(Timestamp.fromDate(new Date(payload.dob)));
  request.setGender(stringToPbGender(payload.gender));
  request.setPhone(payload.phone_no);
  request.setPhoto(base64ToImage(payload.photo));
  request.setVillage(payload.village);

  if (payload.in_group) {
    request.setInGroup(payload.in_group);
  }

  if (payload.cooperative) {
    request.setCooperative(payload.cooperative);
  }

  if (payload.people_in_household) {
    request.setPeopleInHousehold(payload.people_in_household);
  }

  if (payload.land_size) {
    request.setLandSize(payload.land_size);
  }

  if (payload.agent) {
    request.setAgent(payload.agent);
  }

  // Card
  const cardRequest = new CardRequest();
  cardRequest.setNo(payload.card.no);
  cardRequest.setFront(base64ToImage(payload.card.front));
  cardRequest.setBack(base64ToImage(payload.card.back));
  cardRequest.setType(stringToPbCardType(payload.card.type));
  request.setCard(cardRequest);

  return request;
}

export function castToAddCoffeeFarmerReq(
  data: AddCoffeeFarmer
): AddCoffeeFarmerRequest {
  const req = new AddCoffeeFarmerRequest();
  req.setFarmerCode(data.farmer_code);

  req.setDateOfRegistration(Timestamp.fromDate(data.date_of_registration));
  req.setDateOfFirstInspection(
    Timestamp.fromDate(data.date_of_first_inspection)
  );
  req.setDateOfLastInspection(Timestamp.fromDate(data.date_of_last_inspection));
  req.setIsOrganicCompliant(data.is_organic_compliant);
  req.setAltitude(data.altitude);

  if (data.gps_coordinates) {
    const gps_coordinates = new Coordinate();
    gps_coordinates.setLat(data.gps_coordinates?.lat);
    gps_coordinates.setLon(data.gps_coordinates?.lon);

    req.setGpsCoordinates(gps_coordinates);
  }

  if (data.acreage_under_construction) {
    req.setAcreageUnderConstruction(data.acreage_under_construction);
  }

  if (data.acreage_other_crops) {
    req.setAcreageOtherCrops(data.acreage_other_crops);
  }

  req.setNumberCofeePlots(data.number_coffee_plots);
  req.setAcreageForCoffee(data.acreage_for_coffee);
  req.setTotalLandAcreage(data.total_land_acreage);
  req.setTotalCoffeeTrees(data.total_coffee_trees);
  req.setTotalProductiveCoffeeTrees(data.total_productive_coffee_trees);
  req.setParchPerTreePerYear(data.parch_per_tree_per_year);
  req.setTotalYield(data.total_yield);
  req.setVillage(data.village);
  req.setFullName(data.full_name);

  let gender: 0 | 1 | 2 = Gender.GENDER_UNSPECIFIED;
  if (data.gender === "M") {
    gender = Gender.GENDER_MALE;
  } else {
    gender = Gender.GENDER_FEMALE;
  }
  req.setGender(gender);

  if (data.agent) {
    req.setAgent(data.agent);
  }

  return req;
}
