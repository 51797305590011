import { DefaultState, Pagination } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import {
  AgroEnterprise,
  EnterpriseTypes,
  EnterpriseWithVarieties,
} from "./enterprise.types";
import enterpriseService from "@/services/enterprise.service";
import { RootTypes } from "../root.types";

@Module({ namespaced: true })
class EnterpriseModule extends VuexModule {
  public enterprises: AgroEnterprise[] = [];

  public enterprisesWithVarieties: EnterpriseWithVarieties[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public add: DefaultState = this.defaultState;

  public delete: DefaultState = this.defaultState;

  public update: DefaultState = this.defaultState;

  public loadingEnterprise = false;
  public loadingEnterpriseWithVarieties = false;

  // Set enterprise pages count
  @Mutation
  public [EnterpriseTypes.SET_ENTERPRISE_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Enterprise
  @Mutation
  public [EnterpriseTypes.SET_LOADING_SEARCH_ENTERPRISE](
    isLoadingSearchEnterprise: boolean
  ): void {
    this.loadingEnterpriseWithVarieties = isLoadingSearchEnterprise;
  }

  // Load Enterprise
  @Mutation
  public [EnterpriseTypes.SET_LOADING_ENTERPRISE](
    isLoadingEnterprise: boolean
  ): void {
    this.loadingEnterprise = isLoadingEnterprise;
  }

  // Add Enterprise
  @Mutation
  public [EnterpriseTypes.SET_ADD_ENTERPRISE_DIALOG](
    isAddingEnterpriseDialog: boolean
  ): void {
    this.add.dialog = isAddingEnterpriseDialog;
  }

  @Mutation
  public [EnterpriseTypes.INSERT_ENTERPRISES](
    enterprises: AgroEnterprise[]
  ): void {
    this.enterprises.splice(0, this.enterprises.length);
    this.enterprises.push(...enterprises);
  }

  @Mutation
  public [EnterpriseTypes.INSERT_SEARCH_ENTERPRISES](
    enterprises: EnterpriseWithVarieties[]
  ): void {
    this.enterprisesWithVarieties.splice(
      0,
      this.enterprisesWithVarieties.length
    );
    this.enterprisesWithVarieties.push(...enterprises);
  }

  @Mutation
  public [EnterpriseTypes.SET_ADD_ENTERPRISE_LOADING](
    isAddingEnterpriseLoading: boolean
  ): void {
    this.add.loading = isAddingEnterpriseLoading;
  }

  @Mutation
  public [EnterpriseTypes.SET_ADD_ENTERPRISE_ERROR](addError: string): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Enterprise
  @Mutation
  public [EnterpriseTypes.SET_DELETE_ENTERPRISE_DIALOG](
    isDeletingEnterpriseDialog: boolean
  ): void {
    this.delete.dialog = isDeletingEnterpriseDialog;
  }

  @Mutation
  public [EnterpriseTypes.SET_DELETE_ENTERPRISE_LOADING](
    isDeletingEnterpriseLoading: boolean
  ): void {
    this.delete.loading = isDeletingEnterpriseLoading;
  }

  @Mutation
  public [EnterpriseTypes.SET_DELETE_ENTERPRISE_ERROR](
    deleteError: string
  ): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Update Enterprise
  @Mutation
  public [EnterpriseTypes.SET_UPDATE_ENTERPRISE_DIALOG](
    isUpdatingEnterpriseDialog: boolean
  ): void {
    this.update.dialog = isUpdatingEnterpriseDialog;
  }

  @Mutation
  public [EnterpriseTypes.SET_UPDATE_ENTERPRISE_LOADING](
    isUpdatingEnterpriseLoading: boolean
  ): void {
    this.update.loading = isUpdatingEnterpriseLoading;
  }

  @Mutation
  public [EnterpriseTypes.SET_UPDATE_ENTERPRISE_ERROR](
    updateError: string
  ): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Enterprise
  @Mutation
  public [EnterpriseTypes.INSERT_ENTERPRISE](enterprise: AgroEnterprise): void {
    const index = this.enterprises.map((x) => x.id).indexOf(enterprise.id);

    if (index > -1) {
      this.enterprises.splice(index, 1, enterprise);
    } else {
      this.enterprises.splice(0, 0, enterprise);
    }
  }

  // Remove Enterprise
  @Mutation
  public [EnterpriseTypes.REMOVE_ENTERPRISE](enterprise: AgroEnterprise): void {
    const index = this.enterprises.map((x) => x.id).indexOf(enterprise.id);

    if (index > -1) {
      this.enterprises.splice(index);
    }
  }

  // Load Enterprises
  @Action
  public async [EnterpriseTypes.LOAD_ENTERPRISES](): Promise<void> {
    this.context.commit(EnterpriseTypes.SET_LOADING_ENTERPRISE, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const { enterprises } = await enterpriseService.getEnterprises(
        authHeader
      );
      this.context.commit(EnterpriseTypes.INSERT_ENTERPRISES, enterprises);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading enterprises" },
        { root: true }
      );
    } finally {
      this.context.commit(EnterpriseTypes.SET_LOADING_ENTERPRISE, false);
    }
  }

  // Search Enterprises
  @Action
  public async [EnterpriseTypes.SEARCH_ENTERPRISES]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    this.context.commit(EnterpriseTypes.SET_LOADING_SEARCH_ENTERPRISE, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const { enterprises, pages } = await enterpriseService.searchEnterprises(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(
        EnterpriseTypes.INSERT_SEARCH_ENTERPRISES,
        enterprises
      );
      this.context.commit(EnterpriseTypes.SET_ENTERPRISE_PAGES, pages);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading enterprises" },
        { root: true }
      );
    } finally {
      this.context.commit(EnterpriseTypes.SET_LOADING_SEARCH_ENTERPRISE, false);
    }
  }
  // Add Enterprise
  @Action
  public async [EnterpriseTypes.ADD_ENTERPRISE](
    enterprise: AgroEnterprise
  ): Promise<void> {
    this.context.commit(EnterpriseTypes.SET_ADD_ENTERPRISE_LOADING, true);

    try {
      // const fm = await enterpriseService.addEnterprise(enterprise);
      // this.context.commit(EnterpriseTypes.INSERT_ENTERPRISE, fm);
      // this.context.commit(EnterpriseTypes.SET_ADD_ENTERPRISE_DIALOG, false);
      // this.context.commit(
      //   RootTypes.openSnackbar,
      //   { message: "Enterprise Saved", color: "success" },
      //   { root: true }
      // );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add enterprise" },
        { root: true }
      );
    } finally {
      this.context.commit(EnterpriseTypes.SET_ADD_ENTERPRISE_LOADING, false);
    }
  }

  // Delete Enterprise
  @Action
  public async [EnterpriseTypes.DELETE_ENTERPRISE](
    enterprise: AgroEnterprise
  ): Promise<void> {
    this.context.commit(EnterpriseTypes.SET_DELETE_ENTERPRISE_LOADING, true);

    try {
      // const fm = await enterpriseService.deleteEnterprise(enterprise);
      // this.context.commit(EnterpriseTypes.REMOVE_ENTERPRISE, fm);
      // this.context.commit(EnterpriseTypes.SET_DELETE_ENTERPRISE_DIALOG, false);
    } catch (e) {
      this.context.commit(
        EnterpriseTypes.SET_DELETE_ENTERPRISE_ERROR,
        "Failed to delete enterprise"
      );
    } finally {
      this.context.commit(EnterpriseTypes.SET_DELETE_ENTERPRISE_LOADING, false);
    }
  }

  // Update Enterprise
  @Action
  public async [EnterpriseTypes.UPDATE_ENTERPRISE](
    enterprise: AgroEnterprise
  ): Promise<void> {
    this.context.commit(EnterpriseTypes.SET_UPDATE_ENTERPRISE_LOADING, true);

    try {
      // const fm = await enterpriseService.updateEnterprise(enterprise);
      // this.context.commit(EnterpriseTypes.ADD_ENTERPRISE, fm);
      // this.context.commit(EnterpriseTypes.SET_UPDATE_ENTERPRISE_DIALOG, false);
    } catch (e) {
      this.context.commit(
        EnterpriseTypes.SET_UPDATE_ENTERPRISE_ERROR,
        "Failed to update enterprise"
      );
    } finally {
      this.context.commit(EnterpriseTypes.SET_UPDATE_ENTERPRISE_LOADING, false);
    }
  }
}

export default EnterpriseModule;
