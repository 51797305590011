import {
  InputCategoryStats,
  OrderStats,
  OrderStatsPayload,
} from "@/store/modules/stats/stats.types";
import { grpc } from "@improbable-eng/grpc-web";
import { Empty } from "acard-protos/js/meta/meta_pb";
import {
  InputCategoryRequest,
  InputCategoryResponse,
} from "acard-protos/js/v1/statistics/rpc_input_categories_pb";
import {
  MonthlySalesRequest,
  MonthlySalesResponse,
} from "acard-protos/js/v1/statistics/rpc_monthly_sales_pb";
import { Statistics } from "acard-protos/js/v1/statistics/statistics_pb";
import { StatisticsServiceClient } from "acard-protos/js/v1/statistics/statistics_service_pb_service";
import { Timestamp } from "google-protobuf/google/protobuf/timestamp_pb";

const statsClient = new StatisticsServiceClient(process.env.VUE_APP_API_URL);

export function getPortalStats(authKey: string): Promise<Statistics | null> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    statsClient.getPortalStatistics(
      new Empty(),
      metaData,
      (error, response) => {
        if (error != null) {
          reject(error);
        } else {
          resolve(response);
        }
      }
    );
  });
}

export function getOrderStats(
  authKey: string,
  { from, to }: OrderStatsPayload
): Promise<OrderStats> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    // Reques
    const orderStatsReq = new MonthlySalesRequest();
    if (from) orderStatsReq.setFrom(Timestamp.fromDate(from));
    if (to) orderStatsReq.setTo(Timestamp.fromDate(to));

    statsClient.getMonthlySalesStatistics(
      orderStatsReq,
      metaData,
      (error, response) => {
        if (error) {
          reject(error);
        } else {
          if (response) {
            resolve(monthlySalesResponseToOrderStats(response));
          } else {
            reject("No order stats");
          }
        }
      }
    );
  });
}

export function getInputCategoryStats(
  authKey: string
): Promise<InputCategoryStats> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    // Reques
    const inputCatStatsReq = new InputCategoryRequest();

    statsClient.getInputCategoryStatistics(
      inputCatStatsReq,
      metaData,
      (error, response) => {
        if (error) {
          reject(error);
        } else {
          if (response) {
            resolve(inputCategoryResponseToInputCategoryStats(response));
          } else {
            reject("No input cattegory stats");
          }
        }
      }
    );
  });
}

export default {
  getPortalStats,
  getOrderStats,
  getInputCategoryStats,
};

function monthlySalesResponseToOrderStats(
  resp: MonthlySalesResponse
): OrderStats {
  return {
    sales: resp.getSalesList(),
    orders: resp.getOrdersList(),
    labels: resp.getLabelsList(),
    colors: resp.getColorsList(),
  };
}

function inputCategoryResponseToInputCategoryStats(
  resp: InputCategoryResponse
): InputCategoryStats {
  return {
    data: resp.getSeriesList(),
    labels: resp.getLabelsList(),
  };
}
