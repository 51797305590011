import { DefaultState, Pagination } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Produce, ProduceTypes } from "./produce.types";
import produceService from "@/services/produce.service";
import { RootTypes } from "../root.types";

@Module({ namespaced: true })
class ProduceModule extends VuexModule {
  public produces: Produce[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public add: DefaultState = this.defaultState;

  public delete: DefaultState = this.defaultState;

  public update: DefaultState = this.defaultState;

  public loadingProduce = false;

  // Set produce pages count
  @Mutation
  public [ProduceTypes.SET_PRODUCE_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Produce
  @Mutation
  public [ProduceTypes.SET_LOADING_PRODUCE](isLoadingProduce: boolean): void {
    this.loadingProduce = isLoadingProduce;
  }

  // Add Produce
  @Mutation
  public [ProduceTypes.SET_ADD_PRODUCE_DIALOG](isAddingProduceDialog: boolean): void {
    this.add.dialog = isAddingProduceDialog;
  }

  @Mutation
  public [ProduceTypes.INSERT_PRODUCES](produces: Produce[]): void {
    // this.produces.splice(0, this.produces.length);
    // this.produces.push(...produces);
    this.produces.splice(0, this.produces.length);
    this.produces.push(...produces);
  }

  @Mutation
  public [ProduceTypes.SET_ADD_PRODUCE_LOADING](
    isAddingProduceLoading: boolean
  ): void {
    this.add.loading = isAddingProduceLoading;
  }

  @Mutation
  public [ProduceTypes.SET_ADD_PRODUCE_ERROR](addError: string): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Produce
  @Mutation
  public [ProduceTypes.SET_DELETE_PRODUCE_DIALOG](
    isDeletingProduceDialog: boolean
  ): void {
    this.delete.dialog = isDeletingProduceDialog;
  }

  @Mutation
  public [ProduceTypes.SET_DELETE_PRODUCE_LOADING](
    isDeletingProduceLoading: boolean
  ): void {
    this.delete.loading = isDeletingProduceLoading;
  }

  @Mutation
  public [ProduceTypes.SET_DELETE_PRODUCE_ERROR](deleteError: string): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Update Produce
  @Mutation
  public [ProduceTypes.SET_UPDATE_PRODUCE_DIALOG](
    isUpdatingProduceDialog: boolean
  ): void {
    this.update.dialog = isUpdatingProduceDialog;
  }

  @Mutation
  public [ProduceTypes.SET_UPDATE_PRODUCE_LOADING](
    isUpdatingProduceLoading: boolean
  ): void {
    this.update.loading = isUpdatingProduceLoading;
  }

  @Mutation
  public [ProduceTypes.SET_UPDATE_PRODUCE_ERROR](updateError: string): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Produce
  @Mutation
  public [ProduceTypes.INSERT_PRODUCE](produce: Produce): void {
    const index = this.produces.map((x) => x.id).indexOf(produce.id);

    if (index > -1) {
      this.produces.splice(index, 1, produce);
    } else {
      this.produces.splice(0, 0, produce);
    }
  }

  // Remove Produce
  @Mutation
  public [ProduceTypes.REMOVE_PRODUCE](produce: Produce): void {
    const index = this.produces.map((x) => x.id).indexOf(produce.id);

    if (index > -1) {
      this.produces.splice(index);
    }
  }

  // Load Produces
  @Action
  public async [ProduceTypes.LOAD_PRODUCES]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    this.context.commit(ProduceTypes.SET_LOADING_PRODUCE, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const { produces, pages } = await produceService.getProduces(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(ProduceTypes.INSERT_PRODUCES, produces);
      this.context.commit(ProduceTypes.SET_PRODUCE_PAGES, pages);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading produces" },
        { root: true }
      );
    } finally {
      this.context.commit(ProduceTypes.SET_LOADING_PRODUCE, false);
    }
  }
  // Add Produce
  @Action
  public async [ProduceTypes.ADD_PRODUCE](produce: Produce): Promise<void> {
    this.context.commit(ProduceTypes.SET_ADD_PRODUCE_LOADING, true);

    try {
      // const fm = await produceService.addProduce(produce);
      // this.context.commit(ProduceTypes.INSERT_PRODUCE, fm);
      // this.context.commit(ProduceTypes.SET_ADD_PRODUCE_DIALOG, false);
      // this.context.commit(
      //   RootTypes.openSnackbar,
      //   { message: "Produce Saved", color: "success" },
      //   { root: true }
      // );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add produce" },
        { root: true }
      );
    } finally {
      this.context.commit(ProduceTypes.SET_ADD_PRODUCE_LOADING, false);
    }
  }

  // Delete Produce
  @Action
  public async [ProduceTypes.DELETE_PRODUCE](produce: Produce): Promise<void> {
    this.context.commit(ProduceTypes.SET_DELETE_PRODUCE_LOADING, true);

    try {
      // const fm = await produceService.deleteProduce(produce);
      // this.context.commit(ProduceTypes.REMOVE_PRODUCE, fm);
      // this.context.commit(ProduceTypes.SET_DELETE_PRODUCE_DIALOG, false);
    } catch (e) {
      this.context.commit(
        ProduceTypes.SET_DELETE_PRODUCE_ERROR,
        "Failed to delete produce"
      );
    } finally {
      this.context.commit(ProduceTypes.SET_DELETE_PRODUCE_LOADING, false);
    }
  }

  // Update Produce
  @Action
  public async [ProduceTypes.UPDATE_PRODUCE](produce: Produce): Promise<void> {
    this.context.commit(ProduceTypes.SET_UPDATE_PRODUCE_LOADING, true);

    try {
      // const fm = await produceService.updateProduce(produce);
      // this.context.commit(ProduceTypes.ADD_PRODUCE, fm);
      // this.context.commit(ProduceTypes.SET_UPDATE_PRODUCE_DIALOG, false);
    } catch (e) {
      this.context.commit(
        ProduceTypes.SET_UPDATE_PRODUCE_ERROR,
        "Failed to update produce"
      );
    } finally {
      this.context.commit(ProduceTypes.SET_UPDATE_PRODUCE_LOADING, false);
    }
  }
}

export default ProduceModule;
