import { RootState } from "@/store/modules/root.state";
import { RootTypes } from "@/store/modules/root.types";

import Vue, { VueConstructor } from "vue";
import { Store } from "vuex";

export function setUpUtils(
  vueApp: VueConstructor<Vue>,
  store: Store<RootState>
): void {
  // Loading
  vueApp.prototype.$loading = {
    start: function () {
      store.commit(RootTypes.setLoading, true);
    },
    stop: function () {
      store.commit(RootTypes.setLoading, false);
    },
  };

  // Loading
  vueApp.prototype.$snack = {
    open: function (color: string, message: string) {
      store.commit(RootTypes.openSnackbar, { color, message });
    },
    success: function (message: string) {
      store.commit(RootTypes.openSnackbar, { color: "success", message });
    },
    error: function (message: string) {
      store.commit(RootTypes.openSnackbar, { color: "error", message });
    },
    close: function () {
      store.commit(RootTypes.closeSnackbar);
    },
  };
}

export function downloadUint8ArrayAsFile(uint8Array:string | Uint8Array, fileName: string) {
  // Create a Blob from the Uint8Array
  const blob = new Blob([uint8Array], { type: 'application/octet-stream' });

  // Create a download link
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = fileName;

  // Simulate a click on the download link
  downloadLink.click();

  // Clean up
  URL.revokeObjectURL(downloadLink.href);
}