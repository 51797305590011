import { DefaultState, Pagination } from "@/types/types";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Order, OrderTypes } from "./order.types";
import orderService from "@/services/order.service";
import { RootTypes } from "../root.types";

@Module({ namespaced: true })
class OrderModule extends VuexModule {
  public orders: Order[] = [];

  private defaultState: DefaultState = {
    dialog: false,
    loading: false,
    error: {
      error: false,
      errorMessage: null,
    },
  };

  public pages = 0;

  public add: DefaultState = this.defaultState;

  public delete: DefaultState = this.defaultState;

  public update: DefaultState = this.defaultState;

  public loadingOrder = false;

  // Set order pages count
  @Mutation
  public [OrderTypes.SET_ORDER_PAGES](pages: number): void {
    this.pages = pages;
  }

  // Load Order
  @Mutation
  public [OrderTypes.SET_LOADING_ORDER](isLoadingOrder: boolean): void {
    this.loadingOrder = isLoadingOrder;
  }

  // Add Order
  @Mutation
  public [OrderTypes.SET_ADD_ORDER_DIALOG](isAddingOrderDialog: boolean): void {
    this.add.dialog = isAddingOrderDialog;
  }

  @Mutation
  public [OrderTypes.INSERT_ORDERS](orders: Order[]): void {
    this.orders.splice(0, this.orders.length);
    this.orders.push(...orders);
  }

  @Mutation
  public [OrderTypes.SET_ADD_ORDER_LOADING](
    isAddingOrderLoading: boolean
  ): void {
    this.add.loading = isAddingOrderLoading;
  }

  @Mutation
  public [OrderTypes.SET_ADD_ORDER_ERROR](addError: string): void {
    this.add.error.errorMessage = addError;
    this.add.error.error = true;
  }

  // Delete Order
  @Mutation
  public [OrderTypes.SET_DELETE_ORDER_DIALOG](
    isDeletingOrderDialog: boolean
  ): void {
    this.delete.dialog = isDeletingOrderDialog;
  }

  @Mutation
  public [OrderTypes.SET_DELETE_ORDER_LOADING](
    isDeletingOrderLoading: boolean
  ): void {
    this.delete.loading = isDeletingOrderLoading;
  }

  @Mutation
  public [OrderTypes.SET_DELETE_ORDER_ERROR](deleteError: string): void {
    this.delete.error.errorMessage = deleteError;
    this.delete.error.error = true;
  }

  // Update Order
  @Mutation
  public [OrderTypes.SET_UPDATE_ORDER_DIALOG](
    isUpdatingOrderDialog: boolean
  ): void {
    this.update.dialog = isUpdatingOrderDialog;
  }

  @Mutation
  public [OrderTypes.SET_UPDATE_ORDER_LOADING](
    isUpdatingOrderLoading: boolean
  ): void {
    this.update.loading = isUpdatingOrderLoading;
  }

  @Mutation
  public [OrderTypes.SET_UPDATE_ORDER_ERROR](updateError: string): void {
    this.update.error.errorMessage = updateError;
    this.update.error.error = true;
  }

  // Insert Order
  @Mutation
  public [OrderTypes.INSERT_ORDER](order: Order): void {
    const index = this.orders.map((x) => x.id).indexOf(order.id);

    if (index > -1) {
      this.orders.splice(index, 1, order);
    } else {
      this.orders.splice(0, 0, order);
    }
  }

  // Remove Order
  @Mutation
  public [OrderTypes.REMOVE_ORDER](order: Order): void {
    const index = this.orders.map((x) => x.id).indexOf(order.id);

    if (index > -1) {
      this.orders.splice(index);
    }
  }

  // Load Orders
  @Action
  public async [OrderTypes.LOAD_ORDERS]({
    page,
    limit,
    query,
  }: Pagination): Promise<void> {
    this.context.commit(OrderTypes.SET_LOADING_ORDER, true);

    try {
      const authHeader = this.context.rootGetters["Auth/authHeader"];
      const { orders, pages } = await orderService.getOrders(
        authHeader,
        page,
        limit,
        query
      );
      this.context.commit(OrderTypes.INSERT_ORDERS, orders);
      this.context.commit(OrderTypes.SET_ORDER_PAGES, pages);
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Error loading orders" },
        { root: true }
      );
    } finally {
      this.context.commit(OrderTypes.SET_LOADING_ORDER, false);
    }
  }
  // Add Order
  @Action
  public async [OrderTypes.ADD_ORDER](order: Order): Promise<void> {
    this.context.commit(OrderTypes.SET_ADD_ORDER_LOADING, true);

    try {
      // const fm = await orderService.addOrder(order);
      // this.context.commit(OrderTypes.INSERT_ORDER, fm);
      // this.context.commit(OrderTypes.SET_ADD_ORDER_DIALOG, false);
      // this.context.commit(
      //   RootTypes.openSnackbar,
      //   { message: "Order Saved", color: "success" },
      //   { root: true }
      // );
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Failed to add order" },
        { root: true }
      );
    } finally {
      this.context.commit(OrderTypes.SET_ADD_ORDER_LOADING, false);
    }
  }

  // Delete Order
  @Action
  public async [OrderTypes.DELETE_ORDER](order: Order): Promise<void> {
    this.context.commit(OrderTypes.SET_DELETE_ORDER_LOADING, true);

    try {
      // const fm = await orderService.deleteOrder(order);
      // this.context.commit(OrderTypes.REMOVE_ORDER, fm);
      // this.context.commit(OrderTypes.SET_DELETE_ORDER_DIALOG, false);
    } catch (e) {
      this.context.commit(
        OrderTypes.SET_DELETE_ORDER_ERROR,
        "Failed to delete order"
      );
    } finally {
      this.context.commit(OrderTypes.SET_DELETE_ORDER_LOADING, false);
    }
  }

  // Update Order
  @Action
  public async [OrderTypes.UPDATE_ORDER](order: Order): Promise<void> {
    this.context.commit(OrderTypes.SET_UPDATE_ORDER_LOADING, true);

    try {
      // const fm = await orderService.updateOrder(order);
      // this.context.commit(OrderTypes.ADD_ORDER, fm);
      // this.context.commit(OrderTypes.SET_UPDATE_ORDER_DIALOG, false);
    } catch (e) {
      this.context.commit(
        OrderTypes.SET_UPDATE_ORDER_ERROR,
        "Failed to update order"
      );
    } finally {
      this.context.commit(OrderTypes.SET_UPDATE_ORDER_LOADING, false);
    }
  }
}

export default OrderModule;
