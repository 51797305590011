import { pbGenderToString } from "@/store/modules/dealer/dealer.types";
import {
  Farmer,
  FarmerUserProfile,
} from "@/store/modules/farmers/farmers.types";
import { grpc } from "@improbable-eng/grpc-web";
import { FarmerServiceClient } from "acard-protos/js/v1/farmer/farmer_service_pb_service";
import {
  AddCoffeeFarmerRequest,
  AddCoffeeFarmersRequest,
} from "acard-protos/js/v1/farmer/rpc_add_coffee_farmer_pb";
import { AddFarmerRequest } from "acard-protos/js/v1/farmer/rpc_add_farmer_pb";
import { GetFarmersRequest } from "acard-protos/js/v1/farmer/rpc_get_farmers_pb";
import { FarmerProfile } from "acard-protos/js/v1/user/user_pb";

const farmerService = new FarmerServiceClient(process.env.VUE_APP_API_URL);

// Axios config
export function getFarmers(
  authKey: string
): Promise<Array<FarmerUserProfile> | null> {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const getFarmersRequest = new GetFarmersRequest();

    farmerService.getFarmers(getFarmersRequest, metaData, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        let dealers: FarmerUserProfile[] = [];
        if (response) {
          dealers = farmerProfilesToDealers(response.getFarmersList());
        }
        resolve(dealers);
      }
    });
  });
}

export const addFarmer = async (
  authKey: string,
  request: AddFarmerRequest
): Promise<FarmerUserProfile> => {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    farmerService.addFarmer(request, metaData, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        if (response) {
          resolve(farmerProfileToFarmer(response));
        } else {
          reject("No farmer returned");
        }
      }
    });
  });
};

export const addCoffeeFarmer = async (
  authKey: string,
  request: AddCoffeeFarmerRequest
): Promise<FarmerUserProfile> => {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    farmerService.addCoffeeFarmer(request, metaData, (error, response) => {
      if (error != null) {
        reject(error);
      } else {
        if (response) {
          resolve(farmerProfileToFarmer(response));
        } else {
          reject("No farmer returned");
        }
      }
    });
  });
};

export const addCoffeeFarmers = async (
  authKey: string,
  farmers: AddCoffeeFarmerRequest[]
): Promise<{ count: number; errors: string[] }> => {
  return new Promise((resolve, reject) => {
    // Auth
    const metaData = new grpc.Metadata();
    metaData.append("Authorization", authKey);

    const farmersRequest = new AddCoffeeFarmersRequest();
    farmersRequest.setFarmersList(farmers);

    farmerService.addCoffeeFarmers(
      farmersRequest,
      metaData,
      (error, response) => {
        if (error != null) {
          reject(error?.message ?? "Error uploading farmers");
        } else {
          if (response) {
            resolve({
              count: response.getInserted(),
              errors: response.getErrorsList(),
            });
          } else {
            reject("no");
          }
        }
      }
    );
  });
};

export const updateFarmer = async (farmer: Farmer) => {
  // TODO - Send PUT Request to api
};

export const deleteFarmer = async (farmer: Farmer) => {
  // TODO - Send DELETE Request to api
};

export default {
  addFarmer,
  addCoffeeFarmer,
  addCoffeeFarmers,
  updateFarmer,
  deleteFarmer,
  getFarmers,
};

function farmerProfilesToDealers(
  farmerProfiles: FarmerProfile[]
): FarmerUserProfile[] {
  return farmerProfiles.map(farmerProfileToFarmer);
}

function farmerProfileToFarmer(farmer: FarmerProfile) {
  const farmerProfile: FarmerUserProfile = {
    id: farmer.getId(),
    name: farmer.getName(),
    photo: farmer.getPhoto(),
    phone: farmer.getPhone(),
    gender: pbGenderToString(farmer.getGender()) ?? "",
    village: farmer.getVillage(),
    district: farmer.getDistrict(),
    createdAt: farmer.getCreatedAt()?.toDate() ?? new Date(),
    updatedAt: farmer.getUpdatedAt()?.toDate(),
  };

  return farmerProfile;
}
