import { UserProfile } from "acard-protos/js/v1/user/user_pb";

export type AuthState = {
  authenticated: boolean;
  signIn: {
    loading: boolean;
  };
};

export type SignInState = {
  loading: boolean;
  error: boolean;
  errorMessage: string | null;
};

export type SignInPayload = {
  email: string;
  password: string;
};

export type AuthVerifyPayload = {
  code: string;
  secret: string;
};

export type SignByPhonePayload = {
  phone: string;
};

export type RefreshTokenPayload = {
  refresh_token: string;
};

export type TokenPayload = {
  token: string;
  expiry: Date;
};

export type JsUserProfile = {
  name: string;
  phone: string;
  created_at: Date;
};

export enum SignInTypes {
  SET_AUTHENTICATED = "SET_AUTHENTICATED",
  SET_LAST_PATH = "SET_LAST_PATH",
  SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
  SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
  SET_USER_DETAILS = "SET_USER_DETAILS",

  USER_TYPE = "USER_TYPE",

  SIGN_IN = "SIGN_IN",
  VERIFY_SIGN_IN = "VERIFY_SIGN_IN",
  SET_SIGN_IN_LOADING = "SET_SIGN_IN_LOADING",
  SET_SIGN_IN_ERROR = "SET_SIGN_IN_ERROR",
  SET_SIGN_IN_SECRET = "SET_SIGN_IN_SECRET",
  CLEAR_SIGN_IN_ERROR = "CLEAR_SIGN_IN_ERROR",

  SIGN_OUT = "SIGN_OUT",
  SET_SIGN_OUT_LOADING = "SET_SIGN_OUT_LOADING",
  SET_SIGN_OUT_ERROR = "SET_SIGN_OUT_ERROR",
  CLEAR_SIGN_OUT_ERROR = "CLEAR_SIGN_OUT_ERROR",

  REFRESH_TOKEN = "REFRESH_TOKEN",
  SET_REFRESH_TOKEN_LOADING = "SET_REFRESH_TOKEN_LOADING",
  SET_REFRESH_TOKEN_ERROR = "SET_REFRESH_TOKEN_ERROR",
  CLEAR_REFRESH_TOKEN_ERROR = "CLEAR_REFRESH_TOKEN_ERROR",
}

export function pbUserProfileToJsUserProfile(
  profile: UserProfile
): JsUserProfile {
  return {
    name: profile.getName(),
    phone: profile.getPhone(),
    created_at: profile.getCreatedAt()?.toDate() ?? new Date(),
  };
}
