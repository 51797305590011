import authService from "@/services/auth.service";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { RootTypes } from "../root.types";
import router from "@/router";
import {
  JsUserProfile,
  SignByPhonePayload,
  SignInState,
  SignInTypes,
  TokenPayload,
} from "./auth.types";
import CryptoJS from "crypto-js";
import { UserProfile } from "acard-protos/js/v1/user/user_pb";

@Module({ namespaced: true })
class Auth extends VuexModule {
  public authenticated = false;
  public secret: string | null = null;

  private secretKey = process.env.VUE_APP_SECRET_KEY;
  private accessTokenKey = process.env.VUE_APP_ACCESS_TOKEN_KEY;
  private userKey = process.env.VUE_APP_USER_KEY;
  private refreshTokenKey = process.env.VUE_APP_REFRESH_TOKEN_KEY;

  private lastPath = "/";

  public accessToken = this.parseToken(this.accessTokenKey);
  public refreshToken = this.parseToken(this.refreshTokenKey);
  public userProfile = this.parseUserProfile();

  public signIn: SignInState = {
    loading: false,
    error: false,
    errorMessage: null,
  };

  public signOut: SignInState = {
    loading: false,
    error: false,
    errorMessage: null,
  };

  public refreshTokenState: SignInState = {
    loading: false,
    error: false,
    errorMessage: null,
  };

  @Mutation
  public [SignInTypes.SET_AUTHENTICATED](isAuthenticated: boolean): void {
    this.authenticated = isAuthenticated;
  }

  @Mutation
  public [SignInTypes.SET_LAST_PATH](path: string): void {
    this.lastPath = path;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_IN_SECRET](secret: string): void {
    this.secret = secret;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_IN_LOADING](isLoading: boolean): void {
    this.signIn.loading = isLoading;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_IN_ERROR](errorMessage: string): void {
    this.signIn.error = true;
    this.signIn.errorMessage = errorMessage;
  }

  @Mutation
  public [SignInTypes.CLEAR_SIGN_IN_ERROR](): void {
    this.signIn.error = false;
    this.signIn.errorMessage = null;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_OUT_LOADING](isLoading: boolean): void {
    this.signOut.loading = isLoading;
  }

  @Mutation
  public [SignInTypes.SET_SIGN_OUT_ERROR](errorMessage: string): void {
    this.signOut.error = true;
    this.signOut.errorMessage = errorMessage;
  }

  @Mutation
  public [SignInTypes.CLEAR_SIGN_OUT_ERROR](): void {
    this.signOut.error = false;
    this.signOut.errorMessage = null;
  }

  @Mutation
  public [SignInTypes.SET_REFRESH_TOKEN_LOADING](isLoading: boolean): void {
    this.refreshTokenState.loading = isLoading;
  }

  @Mutation
  public [SignInTypes.SET_REFRESH_TOKEN_ERROR](errorMessage: string): void {
    this.refreshTokenState.error = true;
    this.refreshTokenState.errorMessage = errorMessage;
  }

  @Mutation
  public [SignInTypes.CLEAR_REFRESH_TOKEN_ERROR](): void {
    this.refreshTokenState.error = false;
    this.refreshTokenState.errorMessage = null;
  }

  // Tokens
  @Mutation
  public [SignInTypes.SET_ACCESS_TOKEN](token?: TokenPayload): void {
    if (token) {
      console.log(this.accessTokenKey);
      const accessToken = CryptoJS.AES.encrypt(
        JSON.stringify(token),
        this.secretKey
      ).toString();

      localStorage.setItem(this.accessTokenKey, accessToken);

      this.accessToken = token;
    } else {
      localStorage.removeItem(this.accessTokenKey);
      this.accessToken = null;
    }
  }

  @Mutation
  public [SignInTypes.SET_REFRESH_TOKEN](token?: TokenPayload): void {
    if (token) {
      const refreshToken = CryptoJS.AES.encrypt(
        JSON.stringify(token),
        this.secretKey
      ).toString();

      localStorage.setItem(this.refreshTokenKey, refreshToken);

      this.refreshToken = token;
    } else {
      localStorage.removeItem(this.refreshTokenKey);
      this.refreshToken = null;
    }
  }

  @Mutation
  public [SignInTypes.SET_USER_DETAILS](profile?: UserProfile): void {
    if (profile) {
      const user = CryptoJS.AES.encrypt(
        profile.toString(),
        this.secretKey
      ).toString();

      localStorage.setItem(this.userKey, user);

      // this.userProfile = this.parseUserProfile();
    } else {
      this.userProfile = null;
    }
  }

  @Action({ rawError: true })
  public async [SignInTypes.SIGN_IN](
    payload: SignByPhonePayload
  ): Promise<void> {
    this.context.commit(SignInTypes.SET_SIGN_IN_LOADING, true);

    try {
      const result = await authService.signInWithPhone(payload);
      this.context.commit(SignInTypes.CLEAR_SIGN_IN_ERROR);
      this.context.commit(SignInTypes.SET_SIGN_IN_SECRET, result?.getSecret());
    } catch (e) {
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Invalid username or Password" },
        { root: true }
      );
    } finally {
      this.context.commit(SignInTypes.SET_SIGN_IN_LOADING, false);
    }
  }

  @Action({ rawError: true })
  public async [SignInTypes.VERIFY_SIGN_IN](code: string): Promise<void> {
    this.context.commit(SignInTypes.SET_SIGN_IN_LOADING, true);

    try {
      const result = await authService.verifyCode({
        code,
        secret: this.secret || "",
      });
      this.context.commit(SignInTypes.CLEAR_SIGN_IN_ERROR);
      this.context.commit(SignInTypes.SET_AUTHENTICATED, true);

      if (result) {
        // access token
        this.context.commit(SignInTypes.SET_ACCESS_TOKEN, <TokenPayload>{
          token: result.getAccessToken(),
          expiry: result.getAccessTokenExpiresAt()?.toDate() || new Date(),
        });

        // refresh token
        this.context.commit(SignInTypes.SET_REFRESH_TOKEN, <TokenPayload>{
          token: result.getRefreshToken(),
          expiry: result.getRefreshTokenExpiresAt()?.toDate() || new Date(),
        });

        // user details
        this.context.commit(SignInTypes.SET_USER_DETAILS, result.getUser());

        await router.replace("/");
        location.reload();
      }
    } catch (e) {
      console.log(e);
      this.context.commit(
        RootTypes.openSnackbar,
        { message: "Invalid username or Password" },
        { root: true }
      );
    } finally {
      this.context.commit(SignInTypes.SET_SIGN_IN_LOADING, false);
    }
  }

  @Action({ rawError: true })
  public async [SignInTypes.SIGN_OUT](): Promise<void> {
    this.context.commit(SignInTypes.SET_SIGN_OUT_LOADING, true);

    try {
      //TODO-Implement signOut await authService.signOut();
      this.context.commit(SignInTypes.CLEAR_SIGN_OUT_ERROR);
      this.context.commit(SignInTypes.SET_AUTHENTICATED, false);
    } catch (e) {
      this.context.commit(RootTypes.openSnackbar, "Failed to sign out", {
        root: true,
      });
    } finally {
      this.context.commit(SignInTypes.SET_SIGN_IN_LOADING, false);
    }
  }

  @Action({ rawError: true })
  public async [SignInTypes.REFRESH_TOKEN](): Promise<void> {
    if (!this.refreshTokenState.loading) {
      this.context.commit(SignInTypes.SET_REFRESH_TOKEN_LOADING, true);

      try {
        const accessTokenPayload = await authService.refreshAuthToken({
          refresh_token: this.refreshToken?.token ?? "",
        });

        this.context.commit(SignInTypes.SET_ACCESS_TOKEN, accessTokenPayload);
        this.context.commit(SignInTypes.CLEAR_REFRESH_TOKEN_ERROR);
        router.replace(this.lastPath);
        location.reload();
      } catch (e) {
        this.context.commit(RootTypes.openSnackbar, "Failed to refresh token", {
          root: true,
        });
      } finally {
        this.context.commit(SignInTypes.SET_REFRESH_TOKEN_LOADING, false);
      }
    }
  }

  parseToken(key: string): TokenPayload | null {
    const accessTokenHash = localStorage.getItem(key);
    if (accessTokenHash) {
      const dToken = CryptoJS.AES.decrypt(
        accessTokenHash,
        this.secretKey
      ).toString(CryptoJS.enc.Utf8);

      return JSON.parse(dToken) as TokenPayload;
    }
    return null;
  }

  parseUserProfile(): JsUserProfile | null {
    const userHash = localStorage.getItem(this.userKey);
    if (userHash) {
      try {
        const dToken = CryptoJS.AES.decrypt(userHash, this.secretKey).toString(
          CryptoJS.enc.Utf8
        );

        return JSON.parse(dToken) as JsUserProfile;
      } catch (e) {
        return null;
      }
    }
    return null;
  }

  get authHeader(): string | null {
    const token = this.accessToken;
    if (token) {
      return `Bearer ${token.token}`;
    }

    return null;
  }

  get isLoggedIn(): boolean {
    if (this.accessToken) {
      return new Date() < new Date(this.accessToken.expiry);
    }

    return false;
  }

  get isRefreshable(): boolean {
    if (this.refreshToken) {
      return new Date() < new Date(this.refreshToken.expiry);
    }

    return false;
  }

  // get [SignInTypes.USER_TYPE](): string{
  //   return this.userProfile?.
  // }
}
export default Auth;
